import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import MinDialog from "../../../../components/MinDialog";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import API from "../../../../shared/axios";
import { showError } from "../../../../redux/actions/shared";
import MyTopicsPerformance from "./MyTopicsPerformance";
import CircularProgress from "@mui/material/CircularProgress";

const MyPerformance = (props) => {
  const { openMyPerformance, userId, loading } = props;
  const { setOpenMyPerformance, startLoading, stopLoading } = props;
  const [openMyTopicsPerformance, setOpenMyTopicsPerformance] = useState(false);
  const [currentPerformance, setCurrentPerformance] = useState({});

  const [learningAreasAverageScores, setLearningAreasAverageScores] = useState(
    []
  );

  useEffect(() => {
    if (userId) {
      startLoading();
      const fetchData = async () => {
        await API.get(
          `/api/tuition/student/get-user-grade-level-learning-areas-average-scores/${userId}/`
        )
          .then((res) => {
            setLearningAreasAverageScores(
              res?.data?.user_grade_level_learning_areas_average_scores_data
            );
          })
          .catch((err) => showError(err))
          .finally(() => stopLoading());
      };
      fetchData();
    }
  }, [startLoading, stopLoading, userId]);

  const handleOpenTopicPerformance = (performance_item) => {
    setCurrentPerformance(performance_item);
    setOpenMyTopicsPerformance(true);
  };

  return (
    <>
      <MinDialog isOpen={openMyPerformance} maxWidth="700px">
        <div className="dialog" id={loading && "pageLoading"}>
          <h3>My average performance so far</h3>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "45%" }}
            />
          )}
          {learningAreasAverageScores?.length > 0 ? (
            <table className="table__listing" rules="all">
              <tr className="table__listingHeader">
                <th>Learning Area</th>
                <th className="tc">Average Score</th>
                <th className="tc">Average By Topics</th>
              </tr>
              {learningAreasAverageScores?.map((item) => (
                <tr className="table__listingItem" key={item?.id}>
                  <td data-label="Learning Area">{item?.learning_area_name}</td>
                  <td data-label="Average Score" className="tc">
                    {item?.average_score}%
                  </td>
                  <td
                    data-label="Average By Topics"
                    className="dodgerblue bd button__sp tc"
                    onClick={() => handleOpenTopicPerformance(item)}
                  >
                    view
                  </td>
                </tr>
              ))}
            </table>
          ) : (
            <h4 className="not__available">No performance data available</h4>
          )}
          <div className="form__Buttons">
            <button
              className="close__button"
              type="button"
              onClick={() => setOpenMyPerformance(false)}
            >
              Close
            </button>
          </div>
        </div>
      </MinDialog>
      {/* child components */}
      {openMyTopicsPerformance && (
        <MyTopicsPerformance
          openMyTopicsPerformance={openMyTopicsPerformance}
          setOpenMyTopicsPerformance={setOpenMyTopicsPerformance}
          currentPerformance={currentPerformance}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyPerformance);
