import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MaxDialog from "../../../../components/MaxDialog";
import { showError } from "../../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import API from "../../../../shared/axios";
import CircularProgress from "@mui/material/CircularProgress";
import AddTestExam from "./AddTestExam";
import TestExamQuestions from "./TestExamQuestions";
import EditTestExam from "./EditTestExam";
import TestExamResults from "./TestExamResults";

const TestExams = (props) => {
  const { openTestExams, loading, userId } = props;
  const { setOpenTestExams, startLoading, stopLoading } = props;

  const [openAddTestExam, setOpenAddTestExam] = useState(false);
  const [testExamsList, setTestExamsList] = useState([]);
  const [openTestExamQuestions, setOpenTestExamQuestions] = useState(false);
  const [currentTestExam, setCurrentTestExam] = useState({});
  const [openEditTestExam, setOpenEditTestExam] = useState(false);
  const [openTestExamResults, setOpenTestExamResults] = useState(false);

  useEffect(() => {
    if (userId) {
      startLoading();
      const fetchData = async () => {
        await API.get(`/api/tuition/teacher/maintain-test-exams/${userId}/`)
          .then((res) => {
            setTestExamsList(res?.data?.test_exams_data);
          })
          .catch((err) => showError(err))
          .finally(() => stopLoading());
      };
      fetchData();
    }
  }, [userId, startLoading, stopLoading]);

  const handleOpenTestExamQuestions = (item) => {
    setCurrentTestExam(item);
    setOpenTestExamQuestions(true);
  };

  const handleOpenEditTestExam = (item) => {
    setCurrentTestExam(item);
    setOpenEditTestExam(true);
  };

  const handleOpenTestExamResults = (item) => {
    setCurrentTestExam(item);
    setOpenTestExamResults(true);
  };

  const handleDeleteTestExam = async (test_exam_id) => {
    if (
      window.confirm(
        `Deleted test exams cannot be recovered. Do you want to continue?`
      )
    ) {
      startLoading();
      await API.delete(
        `/api/tuition/teacher/delete-test-exam/${userId}/${test_exam_id}/`
      )
        .then((res) => {
          setTestExamsList(
            testExamsList?.filter((item) => item?.id !== test_exam_id)
          );
          window.alert(res?.data?.detail);
        })
        .catch((err) => showError(err))
        .finally(() => stopLoading());
    }
  };
  return (
    <>
      <MaxDialog isOpen={openTestExams} width="800px">
        <div className="dialog" id={loading && "pageLoading"}>
          <h3>Test Exams</h3>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "45%" }}
            />
          )}
          {testExamsList?.length > 0 ? (
            <table className="table__listing" rules="all">
              <tr className="table__listingHeader">
                <th>Name</th>
                <th>Status</th>
                <th className="tc">Edit</th>
                <th className="tc">Questions</th>
                <th className="tc">Delete</th>
                <th className="tc">Results</th>
              </tr>
              {testExamsList?.map((item) => (
                <tr className="table__listingItem" key={item?.id}>
                  <td data-label="Name">{item?.title}</td>
                  <td data-label="Status">
                    {item?.is_active ? (
                      <span className="green bd">Active</span>
                    ) : (
                      <span className="red bd">Inactive</span>
                    )}
                  </td>
                  <td
                    data-label="Edit"
                    className="tc"
                    onClick={() => handleOpenEditTestExam(item)}
                  >
                    <span className="dodgerblue tc button__sp">edit</span>
                  </td>
                  <td
                    data-label="Questions"
                    className="tc"
                    onClick={() => handleOpenTestExamQuestions(item)}
                  >
                    <span className="dodgerblue button__sp tc">
                      View &amp; update
                    </span>
                  </td>
                  <td className="tc" data-label="Delete">
                    <i
                      className="bx bxs-trash-alt button__sp bd red"
                      onClick={() => handleDeleteTestExam(item?.id)}
                    ></i>
                  </td>
                  <td
                    onClick={() => handleOpenTestExamResults(item)}
                    className="tc dodgerblue bd"
                    data-label="Results"
                  >
                    <span className="button__sp">view</span>
                  </td>
                </tr>
              ))}
            </table>
          ) : (
            <h4 className="not__available">No test exams available</h4>
          )}
          <div className="form__Buttons">
            <button
              type="button"
              className="close__button"
              onClick={() => setOpenTestExams(false)}
            >
              Close
            </button>
            <button
              type="button"
              onClick={() => setOpenAddTestExam(true)}
              className="continue__button"
            >
              Add Test Exam
            </button>
          </div>
        </div>
      </MaxDialog>
      {/* child components */}
      {openAddTestExam && (
        <AddTestExam
          openAddTestExam={openAddTestExam}
          setOpenAddTestExam={setOpenAddTestExam}
          setTestExamsList={setTestExamsList}
          testExamsList={testExamsList}
        />
      )}
      {openTestExamQuestions && (
        <TestExamQuestions
          openTestExamQuestions={openTestExamQuestions}
          setOpenTestExamQuestions={setOpenTestExamQuestions}
          currentTestExam={currentTestExam}
        />
      )}
      {openEditTestExam && (
        <EditTestExam
          openEditTestExam={openEditTestExam}
          setOpenEditTestExam={setOpenEditTestExam}
          testExamId={currentTestExam?.id}
          setTestExamsList={setTestExamsList}
          testExamsList={testExamsList}
        />
      )}
      {openTestExamResults && (
        <TestExamResults
          openTestExamResults={openTestExamResults}
          setOpenTestExamResults={setOpenTestExamResults}
          currentTestExam={currentTestExam}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TestExams);
