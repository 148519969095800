import React, { useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../components/MinDialog";
import { showError } from "../../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";
import API from "../../../../shared/axios";

const AddTopic = (props) => {
  const {
    openAddTopic,
    topicsList,
    gradeLevelLearningAreaId,
    userId,
    loading,
  } = props;
  const { setOpenAddTopic, setTopicsList, startLoading, stopLoading } = props;

  const [title, setTitle] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    startLoading();
    await API.post(
      `/api/tuition/staff/maintain-topics/${userId}/${gradeLevelLearningAreaId}/`,
      { title, grade_level_learning_area: gradeLevelLearningAreaId }
    )
      .then((res) => {
        setTopicsList([...topicsList, res?.data?.new_topic_data]);
        setTitle("");
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <MinDialog isOpen={openAddTopic} maxWidth="600px">
      <form
        className="dialog"
        id={loading && "formLoading"}
        onSubmit={handleSubmit}
      >
        <h3>Enter topic details</h3>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Topic title</label>
          <input
            type="text"
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            required
          />
        </div>
        <div className="form__Buttons">
          <button
            type="button"
            onClick={() => setOpenAddTopic(false)}
            className="close__button"
          >
            Close
          </button>
          <button type="submit" className="add__button">
            Submit
          </button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTopic);
