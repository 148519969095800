import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import MaxDialog from "../../components/MaxDialog";
import { TOGGLE_LOADING } from "../../redux/actions/types";
import API from "../../shared/axios";
import RenewSubscription from "../Parent/MyKids/components/RenewSubscription";
import { showError } from "../../redux/actions/shared";

const AvailableTextExamsPage = (props) => {
  const { loading, userId, subscription_expired, kid_name } = props;
  const { startLoading, stopLoading } = props;
  const [trialPeriodMaxExams, setTrialPeriodMaxExams] = useState(false);
  const [openRenewSubscription, setOpenRenewSubscription] = useState(false);

  const navigate = useNavigate();

  const [testExams, setTestExams] = useState([]);

  useEffect(() => {
    if (userId) {
      startLoading();
      const fetchData = async () => {
        await API.get(`/api/tuition/student/get-available-test-exams/${userId}/`)
          .then((res) => {
            if (res?.data?.detail === "trial_period_max_exams") {
              setTrialPeriodMaxExams(true);
            }
            setTestExams(res?.data?.test_exams_data);
          })
          .catch((err) => {
            if (err?.response?.data?.detail === "invalid_grade_level") {
              window.alert("Error, you must specify your grade level to continue");
              navigate("/profile/");
            } else {
              showError(err);
            }
          })
          .finally(() => stopLoading());
      };
      fetchData();
    }
  }, [userId, startLoading, stopLoading, navigate]);

  // we confirm the subscription status of a student if they are class 8
  useEffect(() => {
    if (subscription_expired) {
      navigate("/subscription-expired/", { replace: true });
    }
  }, [navigate, subscription_expired]);

  return (
    <>
      <div className="dialog max-w-[1100px] m-auto" id={loading && "pageLoading"}>
        <h3>Available Test Exams</h3>
        {loading && <CircularProgress style={{ position: "absolute", marginLeft: "45%" }} />}
        {testExams?.length > 0 ? (
          <table className="table__listing" rules="all">
            <tr className="table__listingHeader">
              <th>Learning Area</th>
              <th>Title</th>
              <th className="tc">Action</th>
            </tr>
            {testExams?.map((item) => (
              <tr className="table__listingItem" key={item?.id}>
                <td data-label="Learning Area">{item?.learning_area}</td>
                <td data-label="Title">{item?.title}</td>
                <td className="tc bd" data-label="Action">
                  <Link to={`/test-exam-page/${item?.id}/`} className="dodgerblue">
                    Open
                  </Link>
                </td>
              </tr>
            ))}
          </table>
        ) : (
          <h4 className="not__available">No test exams available</h4>
        )}
        {trialPeriodMaxExams && (
          <div className="tc">
            <p>You have done all the tests allowed for trial subscription.</p>
            <p>Upgrade to access more tests.</p>
            <button className="add__button" onClick={() => setOpenRenewSubscription(true)}>
              Upgrade Now
            </button>
          </div>
        )}
      </div>

      {/* child components */}
      {openRenewSubscription && (
        <RenewSubscription
          openRenewSubscription={openRenewSubscription}
          setOpenRenewSubscription={setOpenRenewSubscription}
          currentKid={{ id: userId, kid_name }}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
    subscription_expired: state?.auth?.user?.subscription_expired,
    kid_name: `${state?.auth?.user?.first_name} ${state?.auth?.user?.last_name}`,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AvailableTextExamsPage);
