import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MaxDialog from "../../../../components/MaxDialog";
import { showError } from "../../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";
import API from "../../../../shared/axios";
import AddTeacher from "./AddTeacher";
import TeacherLearningAreas from "./TeacherLearningAreas";

const Teachers = (props) => {
  const { openTeachers, userId, loading } = props;
  const { setOpenTeachers, startLoading, stopLoading } = props;

  const [teachersList, setTeachersList] = useState([]);
  // note pageNumber must start with blank to avoid sending the query twice
  const [pageNumber, setPageNumber] = useState("");
  const [hasNextPage, setHasNextPage] = useState(false);
  const [openAddTeacher, setOpenAddTeacher] = useState(false);
  const [openTeacherLearningAreas, setOpenTeacherLearningAreas] = useState(false);
  const [currentTeacher, setCurrentTeacher] = useState({});

  useEffect(() => {
    if (userId) {
      startLoading();
      const fetchData = async () => {
        await API.get(`/api/user/staff/maintain-teachers/${userId}`)
          .then((res) => {
            setTeachersList(res?.data?.teachers_data);
            if (res?.data?.has_next) {
              setPageNumber(Number(res?.data?.current_page) + 1);
            }
            setHasNextPage(res?.data?.has_next);
          })
          .catch((err) => showError(err))
          .finally(() => stopLoading());
      };
      fetchData();
    }
  }, [userId, startLoading, stopLoading]);

  const loadMorePages = async () => {
    if (hasNextPage) {
      await API.get(`/api/user/staff/maintain-teachers/${userId}?page=${pageNumber}`)
        .then((res) => {
          setTeachersList([...teachersList, ...res?.data?.teachers_data]);
          if (res?.data?.has_next) {
            setPageNumber(Number(res?.data?.current_page) + 1);
          }
          setHasNextPage(res?.data?.has_next);
        })
        .catch((err) => showError(err))
        .finally(() => stopLoading());
    }
  };

  const handleOpenTeacherLearningAreas = (item) => {
    setCurrentTeacher(item);
    setOpenTeacherLearningAreas(true);
  };

  return (
    <>
      <MaxDialog isOpen={openTeachers} width="800px">
        <div className="dialog" id={loading && "pageLoading"}>
          <h3>Current Teachers</h3>
          {loading && <CircularProgress style={{ position: "absolute", marginLeft: "45%" }} />}
          {teachersList?.length > 0 ? (
            <>
              <table className="table__listing" rules="all" border="1">
                <thead>
                  <tr className="table__listingHeader">
                    <th>Name</th>
                    <th>Phone</th>
                    <th className="tc">Learning Areas</th>
                  </tr>
                </thead>
                <tbody>
                  {teachersList?.map((item) => (
                    <tr className="table__listingItem" key={item?.id}>
                      <td data-label="First Name">{item?.first_name}</td>
                      <td data-label="Phone Number">{item?.phone_number}</td>
                      <td data-label="Learning Areas" className="tc dodgerblue bd button__sp" onClick={() => handleOpenTeacherLearningAreas(item)}>
                        View
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {hasNextPage && (
                <div style={{ textAlign: "center" }}>
                  <button
                    type="button"
                    onClick={loadMorePages}
                    style={{
                      borderRadius: "10px",
                      color: "dodgerblue",
                      border: "1px solid dodgerblue",
                    }}
                  >
                    Load More
                  </button>
                </div>
              )}
            </>
          ) : (
            <h4 className="not__available">No teachers added yet</h4>
          )}
          <div className="form__Buttons">
            <button type="button" className="close__button" onClick={() => setOpenTeachers(false)}>
              Close
            </button>
            <button className="continue__button" onClick={() => setOpenAddTeacher(true)}>
              Add Teacher
            </button>
          </div>
        </div>
      </MaxDialog>
      {/* child components */}
      {openAddTeacher && (
        <AddTeacher openAddTeacher={openAddTeacher} setOpenAddTeacher={setOpenAddTeacher} setTeachersList={setTeachersList} teachersList={teachersList} />
      )}
      {openTeacherLearningAreas && (
        <TeacherLearningAreas
          openTeacherLearningAreas={openTeacherLearningAreas}
          setOpenTeacherLearningAreas={setOpenTeacherLearningAreas}
          currentTeacher={currentTeacher}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Teachers);
