import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { get_user_data } from "../../../redux/actions/auth";
import { TOGGLE_LOADING, TOGGLE_LOGIN, TOGGLE_SIGNUP } from "../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";
import MinDialog from "../../MinDialog";
import API from "../../../shared/axios";
import { showError } from "../../../redux/actions/shared";
import SelectLoginUser from "./components/SelectLoginUser";

const Login = (props) => {
  const { loginForm, loading } = props;
  const { closeLogin, startLoading, openSignUp, stopLoading, getUserData } = props;

  const navigate = useNavigate();
  const [loginDetails, setLoginDetails] = useState({
    phone_number: "",
    password: "",
  });

  const { phone_number, password } = loginDetails;

  const [openSelectLoginUser, setOpenSelectLoginUser] = useState(false);
  const [studentsAvailable, setStudentsAvailable] = useState(false);
  const [parentUser, setParentUser] = useState({});

  const handleChange = (e) => {
    setLoginDetails({ ...loginDetails, [e.target.name]: e.target.value });
  };

  const handleOpenSignup = () => {
    openSignUp();
    closeLogin();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    startLoading();
    await API.post(`/api/user/login/`, loginDetails)
      .then((res) => {
        if (res?.data?.detail === "non_parent") {
          localStorage.setItem("access_token", res?.data?.access_token);
          closeLogin();
          getUserData(navigate);
        } else if (res?.data?.detail === "parent_user") {
          setStudentsAvailable(res?.data?.students_available);
          setParentUser(res?.data?.parent_user);
          setOpenSelectLoginUser(true);
        }
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <>
      <MinDialog isOpen={loginForm} maxWidth="500px">
        <form className="dialog" onSubmit={handleSubmit} id={loading && "formLoading"}>
          <h3>Login to continue</h3>
          <div className="dialog__rowSingleItem">
            <label htmlFor="">Phone Number</label>
            <input type="text" name="phone_number" onChange={handleChange} value={phone_number} required />
          </div>
          {loading && <CircularProgress style={{ position: "absolute", marginLeft: "45%" }} />}
          <div className="dialog__rowSingleItem">
            <label htmlFor="">Your Password</label>
            <input type="password" name="password" onChange={handleChange} value={password} required />
          </div>
          <p style={{ textAlign: "center" }}>
            Don't have an account?{" "}
            <button
              type="button"
              style={{
                background: "transparent",
                color: "dodgerblue",
                fontWeight: "bold",
              }}
              onClick={handleOpenSignup}
            >
              Join Now
            </button>
          </p>
          <div className="form__Buttons">
            <button type="button" className="close__button" onClick={closeLogin}>
              Close
            </button>
            <button type="submit" className="continue__button">
              Login
            </button>
          </div>
        </form>
      </MinDialog>
      {/* child components */}
      {openSelectLoginUser && (
        <SelectLoginUser
          openSelectLoginUser={openSelectLoginUser}
          setOpenSelectLoginUser={setOpenSelectLoginUser}
          studentsAvailable={studentsAvailable}
          parentUser={parentUser}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loginForm: state?.auth?.loginForm,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeLogin: () => dispatch({ type: TOGGLE_LOGIN, payload: false }),
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
    openSignUp: () => dispatch({ type: TOGGLE_SIGNUP, payload: true }),
    getUserData: (navigate) => dispatch(get_user_data(navigate)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
