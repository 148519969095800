import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../components/MinDialog";
import { showError } from "../../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";
import API from "../../../../shared/axios";
import SetNewPassword from "../../../../components/users/SetNewPassword";
import EditParent from "./EditParent";
import AddParent from "./AddParent";
import ParentKids from "./ParentKids";

const MyParents = (props) => {
  const { openMyParents, userId, loading } = props;
  const { setOpenMyParents, startLoading, stopLoading } = props;

  const [parentsList, setParentsList] = useState([]);
  // note pageNumber must start with blank to avoid sending the query twice
  const [pageNumber, setPageNumber] = useState("");
  const [hasNextPage, setHasNextPage] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [openSetNewPassword, setOpenNewPassword] = useState(false);
  const [openEditParent, setOpenEditParent] = useState(false);
  const [openAddParent, setOpenAddParent] = useState(false);
  const [openParentKids, setOpenParentKids] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    if (userId) {
      startLoading();
      const fetchData = async () => {
        await API.get(`/api/user/teacher/maintain-parents/${userId}`)
          .then((res) => {
            setParentsList(res?.data?.parents_data);
            if (res?.data?.has_next) {
              setPageNumber(Number(res?.data?.current_page) + 1);
            }
            setHasNextPage(res?.data?.has_next);
          })
          .catch((err) => showError(err))
          .finally(() => stopLoading());
      };
      fetchData();
    }
  }, [userId, startLoading, stopLoading]);

  const loadMorePages = async () => {
    if (hasNextPage) {
      await API.get(
        `/api/user/teacher/maintain-parents/${userId}?page=${pageNumber}`
      )
        .then((res) => {
          setParentsList([...parentsList, ...res?.data?.parents_data]);
          if (res?.data?.has_next) {
            setPageNumber(Number(res?.data?.current_page) + 1);
          }
          setHasNextPage(res?.data?.has_next);
        })
        .catch((err) => showError(err))
        .finally(() => stopLoading());
    }
  };

  const handleOpenSetNewPassword = (item) => {
    setCurrentUser(item);
    setOpenNewPassword(true);
  };

  const handleOpenEditParent = (item) => {
    setCurrentUser(item);
    setOpenEditParent(true);
  };

  const handleDeleteParent = async (parentId) => {
    if (
      window.confirm(
        `Once you delete this parent, their data will not be recoverable. Do you want to continue?`
      )
    ) {
      startLoading();
      await API.delete(`/api/user/teacher/delete-parent/${userId}/${parentId}/`)
        .then((res) => {
          setParentsList(parentsList?.filter((item) => item?.id !== parentId));
          window.alert(res?.data?.detail);
        })
        .catch((err) => showError(err))
        .finally(() => stopLoading());
    }
  };

  const searchParent = async (e) => {
    e.preventDefault();
    startLoading();
    await API.post(`/api/user/teacher/search-parent/${userId}/`, {
      phoneNumber,
    })
      .then((res) => {
        setParentsList([res?.data?.parent_data]);
        // to avoid errors of loading more since we only search for one user, we reset the hasNextPage and pageNumber to initial values
        setHasNextPage(false);
        setPageNumber("");
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  const handleOpenParentKids = (item) => {
    setCurrentUser(item);
    setOpenParentKids(true);
  };

  return (
    <>
      <MinDialog isOpen={openMyParents} maxWidth="700px">
        <div className="dialog" id={loading && "pageLoading"}>
          <h3>My parents list</h3>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "45%" }}
            />
          )}
          <form className="dialog__row" onSubmit={searchParent}>
            <div>
              <label htmlFor="">Search by Phone number</label>
              <input
                type="text"
                onChange={(e) => setPhoneNumber(e.target.value)}
                value={phoneNumber}
                required
              />
            </div>
            <div>
              <label htmlFor=""></label>
              <button className="add__button" type="submit">
                Search
              </button>
            </div>
          </form>
          <br />
          {parentsList?.length > 0 ? (
            <>
              <table className="table__listing" rules="all">
                <thead>
                  <tr className="table__listingHeader">
                    <th>Name</th>
                    <th>Phone Number</th>
                    <th>Edit</th>
                    <th>Kids</th>
                    <th className="tc">New Password</th>
                    <th className="tc">Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {parentsList?.map((item) => (
                    <tr className="table__listingItem" key={item?.id}>
                      <td data-label="Name">
                        {item?.first_name} {item?.last_name}
                      </td>
                      <td data-label="Phone Number">{item?.phone_number}</td>

                      <td
                        data-label="Edit"
                        onClick={() => handleOpenEditParent(item)}
                      >
                        <span className="dodgerblue button__sp bd tc">
                          edit
                        </span>
                      </td>
                      <td
                        data-label="Kids"
                        onClick={() => handleOpenParentKids(item)}
                      >
                        <span className="tc button__sp bd dodgerblue">
                          view &amp; update
                        </span>
                      </td>
                      <td
                        data-label="New Password"
                        onClick={() => handleOpenSetNewPassword(item)}
                      >
                        <span className="tc dodgerblue button__sp bd">
                          Set Password
                        </span>
                      </td>
                      <td className="tc" data-label="Delete">
                        <i
                          className="bx bxs-trash-alt button__sp red bd"
                          onClick={() => handleDeleteParent(item?.id)}
                        ></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {hasNextPage && (
                <div style={{ textAlign: "center" }}>
                  <button
                    type="button"
                    onClick={loadMorePages}
                    style={{
                      borderRadius: "10px",
                      color: "dodgerblue",
                      border: "1px solid dodgerblue",
                    }}
                  >
                    Load More
                  </button>
                </div>
              )}
            </>
          ) : (
            <h4 className="not__available">No parents available</h4>
          )}
          <div className="form__Buttons">
            <button
              className="close__button"
              type="button"
              onClick={() => setOpenMyParents(false)}
            >
              Close
            </button>
            <button
              className="continue__button"
              type="button"
              onClick={() => setOpenAddParent(true)}
            >
              Add Parent
            </button>
          </div>
        </div>
      </MinDialog>
      {/* child components */}
      {openSetNewPassword && (
        <SetNewPassword
          openSetNewPassword={openSetNewPassword}
          setOpenNewPassword={setOpenNewPassword}
          currentUser={currentUser}
        />
      )}
      {openAddParent && (
        <AddParent
          openAddParent={openAddParent}
          setOpenAddParent={setOpenAddParent}
          parentsList={parentsList}
          setParentsList={setParentsList}
        />
      )}
      {openEditParent && (
        <EditParent
          openEditParent={openEditParent}
          setOpenEditParent={setOpenEditParent}
          setCurrentUser={setCurrentUser}
          currentUser={currentUser}
          parentsList={parentsList}
          setParentsList={setParentsList}
        />
      )}
      {openParentKids && (
        <ParentKids
          openParentKids={openParentKids}
          setOpenParentKids={setOpenParentKids}
          currentUser={currentUser}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyParents);
