import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MaxDialog from "../../../../components/MaxDialog";
import { showError } from "../../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";
import API from "../../../../shared/axios";
import AddTeacherLearningAreas from "./AddTeacherLearningAreas";

const TeacherLearningAreas = (props) => {
  const { currentTeacher, openTeacherLearningAreas, loading, userId } = props;
  const { setOpenTeacherLearningAreas, startLoading, stopLoading } = props;

  const [teacherLearningAreasList, setTeacherLearningAreasList] = useState([]);
  // note pageNumber must start with blank to avoid sending the query twice
  const [pageNumber, setPageNumber] = useState("");
  const [hasNextPage, setHasNextPage] = useState(false);
  const [openAddTeacherLearningAreas, setOpenAddTeacherLearningAreas] =
    useState(false);

  useEffect(() => {
    if (userId) {
      const fetchData = async () => {
        await API.get(
          `/api/tuition/staff/maintain-teacher-learning-areas/${userId}/${currentTeacher?.id}`
        )
          .then((res) => {
            setTeacherLearningAreasList(res?.data?.teacher_learning_areas_data);
            if (res?.data?.has_next) {
              setPageNumber(Number(res?.data?.current_page) + 1);
            }
            setHasNextPage(res?.data?.has_next);
          })
          .catch((err) => showError(err))
          .finally(() => stopLoading());
      };
      fetchData();
    }
  }, [userId, startLoading, stopLoading, currentTeacher?.id]);

  const loadMorePages = async () => {
    if (hasNextPage) {
      await API.get(
        `/api/tuition/staff/maintain-teacher-learning-areas/${userId}/${currentTeacher?.id}?page=${pageNumber}`
      )
        .then((res) => {
          setTeacherLearningAreasList([
            ...teacherLearningAreasList,
            ...res?.data?.teacher_learning_areas_data,
          ]);
          if (res?.data?.has_next) {
            setPageNumber(Number(res?.data?.current_page) + 1);
          }
          setHasNextPage(res?.data?.has_next);
        })
        .catch((err) => showError(err))
        .finally(() => stopLoading());
    }
  };

  return (
    <>
      <MaxDialog isOpen={openTeacherLearningAreas} width="800px">
        <div className="dialog" id={loading && "pageLoading"}>
          <h3>{currentTeacher?.first_name} learning areas</h3>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "45%" }}
            />
          )}
          {teacherLearningAreasList?.length > 0 ? (
            <>
              <table className="table__listing" rules="all" border="1">
                <thead>
                  <tr className="table__listingHeader">
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  {teacherLearningAreasList?.map((item) => (
                    <tr className="table__listingItem" key={item?.id}>
                      <td>{item?.name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {hasNextPage && (
                <div style={{ textAlign: "center" }}>
                  <button
                    type="button"
                    onClick={loadMorePages}
                    style={{
                      borderRadius: "10px",
                      color: "dodgerblue",
                      border: "1px solid dodgerblue",
                    }}
                  >
                    Load More
                  </button>
                </div>
              )}
            </>
          ) : (
            <h4 className="not__available">No learning areas added yet</h4>
          )}
          <div className="form__Buttons">
            <button
              className="close__button"
              type="button"
              onClick={() => setOpenTeacherLearningAreas(false)}
            >
              Close
            </button>
            <button
              className="continue__button"
              type="button"
              onClick={() => setOpenAddTeacherLearningAreas(true)}
            >
              Add Learning Area
            </button>
          </div>
        </div>
      </MaxDialog>
      {/* child components */}
      {openAddTeacherLearningAreas && (
        <AddTeacherLearningAreas
          openAddTeacherLearningAreas={openAddTeacherLearningAreas}
          setOpenAddTeacherLearningAreas={setOpenAddTeacherLearningAreas}
          teacherLearningAreasList={teacherLearningAreasList}
          setTeacherLearningAreasList={setTeacherLearningAreasList}
          currentTeacherId={currentTeacher?.id}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeacherLearningAreas);
