import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../components/MinDialog";
import { showError } from "../../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";
import API from "../../../../shared/axios";
import AddParentKid from "./AddParentKid";
import EditParentKid from "./EditParentKid";

const ParentKids = (props) => {
  const { openParentKids, currentUser, userId, loading } = props;
  const { setOpenParentKids, startLoading, stopLoading } = props;

  const [kids, setKids] = useState([]);
  const [openEditParentKid, setOpenEditParentKid] = useState(false);
  const [openAddParentKid, setOpenAddParentKid] = useState(false);
  const [currentParentKid, setCurrentParentKid] = useState({});

  useEffect(() => {
    if (userId && currentUser?.id) {
      startLoading();
      const fetchData = async () => {
        await API.get(
          `/api/user/teacher/maintain-parent-kids/${userId}/${currentUser?.id}`
        )
          .then((res) => {
            setKids(res?.data?.parent_kids_data);
          })
          .catch((err) => showError(err))
          .finally(() => stopLoading());
      };
      fetchData();
    }
  }, [userId, startLoading, stopLoading, currentUser?.id]);

  const handleOpenEditParentKid = (item) => {
    setCurrentParentKid(item);
    setOpenEditParentKid(true);
  };

  const handleDeleteKid = async (kidId) => {
    if (
      window.confirm(
        `Kids data cannot be recovered after deleting. Do you want to continue?`
      )
    ) {
      startLoading();
      await API.delete(
        `/api/user/teacher/delete-parent-kid/${userId}/${kidId}/`
      )
        .then((res) => {
          setKids(kids?.filter((item) => item?.id !== kidId));
          window.alert(res?.data?.detail);
        })
        .catch((err) => showError(err))
        .finally(() => stopLoading());
    }
  };

  return (
    <>
      <MinDialog isOpen={openParentKids} maxWidth="700px">
        <div className="dialog" id={loading && "formLoading"}>
          <h3>
            {currentUser?.first_name} {currentUser?.last_mame} kids
          </h3>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "45%" }}
            />
          )}
          {kids?.length > 0 ? (
            <table className="table__listing" rules="all">
              <thead>
                <tr className="table__listingHeader">
                  <th>Name</th>
                  <th className="tc">Grade Level</th>
                  <th className="tc">Edit</th>
                  <th className="tc">Delete</th>
                </tr>
              </thead>
              <tbody>
                {kids?.map((item) => (
                  <tr className="table__listingItem" key={item?.id}>
                    <td data-label="Name">
                      {item?.first_name} {item?.last_name}
                    </td>
                    <td className="tc" data-label="Grade Level">
                      {item?.grade_level_name}
                    </td>
                    <td
                      data-label="Edit"
                      onClick={() => handleOpenEditParentKid(item)}
                    >
                      <span className="tc button__sp dodgerblue bd"></span>
                      edit
                    </td>
                    <td className="tc" data-label="Delete">
                      <i
                        className="bx bxs-trash-alt red button__sp bd"
                        onClick={() => handleDeleteKid(item?.id)}
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <h4 className="not__available">No kids added for this parent</h4>
          )}
          <div className="form__Buttons">
            <button
              className="close__button"
              type="button"
              onClick={() => setOpenParentKids(false)}
            >
              Close
            </button>

            <button
              className="continue__button"
              type="button"
              onClick={() => setOpenAddParentKid(true)}
            >
              Add Kid
            </button>
          </div>
        </div>
      </MinDialog>
      {/* child components */}
      {openEditParentKid && (
        <EditParentKid
          openEditParentKid={openEditParentKid}
          setOpenEditParentKid={setOpenEditParentKid}
          currentParentKid={currentParentKid}
          setCurrentParentKid={setCurrentParentKid}
          currentUser={currentUser} // this is the parent
          kids={kids}
          setKids={setKids}
        />
      )}
      {openAddParentKid && (
        <AddParentKid
          openAddParentKid={openAddParentKid}
          setOpenAddParentKid={setOpenAddParentKid}
          currentUser={currentUser}
          kids={kids}
          setKids={setKids}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ParentKids);
