import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../components/MinDialog";
import { showError } from "../../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";
import API from "../../../../shared/axios";

const AddTestExam = (props) => {
  const { openAddTestExam, userId, loading, testExamsList, myLearningAreas } = props;
  const { setOpenAddTestExam, startLoading, stopLoading, setTestExamsList } = props;

  const [textExamDetails, setTestExamDetails] = useState({
    title: "",
    grade_level_learning_area: "",
    topic: "",
  });
  const { title, grade_level_learning_area, topic } = textExamDetails;
  const [topicsList, setTopicsList] = useState([]);

  useEffect(() => {
    if (userId && grade_level_learning_area !== "") {
      const fetchData = async () => {
        await API.get(`/api/tuition/staff/maintain-topics/${userId}/${grade_level_learning_area}/`)
          .then((res) => {
            setTopicsList(res?.data?.topics_data);
          })
          .catch((err) => showError(err))
          .finally(() => {});
      };
      fetchData();
    }
  }, [userId, grade_level_learning_area]);

  const handleChange = (e) => {
    setTestExamDetails({
      ...textExamDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    startLoading();
    await API.post(`/api/tuition/teacher/maintain-test-exams/${userId}/`, {
      ...textExamDetails,
      created_by: userId,
    })
      .then((res) => {
        setTestExamsList([...testExamsList, res?.data?.new_test_exam_data]);
        setTestExamDetails({
          title: "",
          pass_mark: "",
          grade_level_learning_area: "",
          topic: "",
        });
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <MinDialog isOpen={openAddTestExam} maxWidth="500px">
      <form className="dialog" id={loading && "formLoading"} onSubmit={handleSubmit}>
        <h3>Test Exam details</h3>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Learning Area</label>
          <select name="grade_level_learning_area" onChange={handleChange} value={grade_level_learning_area} required>
            <option value="">--select--</option>
            {myLearningAreas?.map((item) => (
              <option value={item?.id} key={item?.id}>
                {item?.name}
              </option>
            ))}
          </select>
        </div>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Topic</label>
          <select name="topic" onChange={handleChange} value={topic} required>
            <option value="">--select--</option>
            {topicsList?.map((item) => (
              <option value={item?.id} key={item?.id}>
                {item?.title}
              </option>
            ))}
          </select>
        </div>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Test Exam title</label>
          <input type="text" name="title" onChange={handleChange} value={title} required />
        </div>
        {loading && <CircularProgress style={{ position: "absolute", marginLeft: "45%" }} />}

        <div className="form__Buttons">
          <button type="button" onClick={() => setOpenAddTestExam(false)} className="close-button">
            Close
          </button>
          <button type="submit" className="continue-button">
            Submit
          </button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
    myLearningAreas: state?.tuition?.myLearningAreas,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTestExam);
