import React, { useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../components/MinDialog";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";
import { add_new_kid } from "../../../../redux/actions/auth";

const AddKid = (props) => {
  const { openAddKid, loading, userId, gradeLevels } = props;
  const { setOpenAddKid, startLoading, addNewKid } = props;

  const [kidDetails, setKidDetails] = useState({
    first_name: "",
    last_name: "",
    grade_level: "",
  });
  const { first_name, last_name, grade_level } = kidDetails;

  const handleChange = (e) => {
    setKidDetails({ ...kidDetails, [e.target.name]: e.target.value });
  };

  const resetForm = () => {
    setKidDetails({
      first_name: "",
      last_name: "",
      grade_level: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    startLoading();
    addNewKid(userId, { ...kidDetails, parent: userId }, resetForm);
  };

  return (
    <MinDialog isOpen={openAddKid} maxWidth="600px">
      <form
        className="dialog"
        onSubmit={handleSubmit}
        id={loading && "formLoading"}
      >
        <h3>Enter kid details</h3>
        <div className="dialog__row">
          <div>
            <label htmlFor="">First Name</label>
            <input
              type="text"
              name="first_name"
              onChange={handleChange}
              value={first_name}
              required
            />
          </div>
          <div>
            <label htmlFor="">Last Name</label>
            <input
              type="text"
              name="last_name"
              onChange={handleChange}
              value={last_name}
              required
            />
          </div>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div className="dialog__row">
          <div>
            <label htmlFor="">Grade Level</label>
            <select
              name="grade_level"
              onChange={handleChange}
              value={grade_level}
              required
            >
              <option value="">--select--</option>
              {gradeLevels?.map((item) => (
                <option value={item?.id} key={item?.id}>
                  {item?.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="form__Buttons">
          <button
            className="close__button"
            type="button"
            onClick={() => setOpenAddKid(false)}
          >
            Close
          </button>
          <button type="submit" className="continue__button">
            Submit
          </button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
    gradeLevels: state?.tuition?.gradeLevels,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
    addNewKid: (userId, body, resetForm) =>
      dispatch(add_new_kid(userId, body, resetForm)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddKid);
