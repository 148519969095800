import * as api from "../../api";
import { showError, stopLoading } from "./shared";
import * as actionTypes from "./types";

export const teacher_get_grade_levels = (userId) => async (dispatch) => {
  await api
    .teacherGetGradeLevels(userId)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_GRADE_LEVELS,
        payload: {
          grade_levels_data: res?.data?.grade_levels_data,
          learning_areas_data: res?.data?.learning_areas_data,
        },
      });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

export const get_my_learning_areas = (userId) => async (dispatch) => {
  await api
    .getMyLearningAreas(userId)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_MY_LEARNING_AREAS,
        payload: res?.data?.my_learning_areas_data,
      });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

export const get_grade_levels = (userId) => async (dispatch) => {
  await api
    .getGradeLevels(userId)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_GRADE_LEVELS,
        payload: { grade_levels_data: res?.data?.grade_levels_data },
      });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

export const get_all_grade_level_learning_areas =
  (userId) => async (dispatch) => {
    await api
      .getAllGradeLevelLearningAreas(userId)
      .then((res) => {
        dispatch({
          type: actionTypes.SET_GRADE_LEVEL_LEARNING_AREAS,
          payload: res?.data?.grade_level_learning_areas_data,
        });
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading(dispatch));
  };
