import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signup_user } from "../../redux/actions/auth";
import CircularProgress from "@mui/material/CircularProgress";
import { TOGGLE_LOADING, TOGGLE_LOGIN, TOGGLE_SIGNUP } from "../../redux/actions/types";

import MinDialog from "../MinDialog";

const Signup = (props) => {
  const { signupForm, loading } = props;
  const { closeSignup, startLoading, signupUser, openLogin } = props;

  const navigate = useNavigate();

  const [signupDetails, setSignupDetails] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    password: "",
    confirm_password: "",
  });

  const { first_name, last_name, phone_number, password, confirm_password } = signupDetails;

  const handleChange = (e) => {
    setSignupDetails({ ...signupDetails, [e.target.name]: e.target.value });
  };

  const handleOpenLogin = () => {
    openLogin();
    closeSignup();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // check if password length is at least 6
    if (password?.trim()?.length < 4) {
      return window.alert("Error, password must be at least 4 characters");
    }
    if (password?.trim() !== confirm_password?.trim()) {
      return window.alert("Error, passwords must match");
    }
    startLoading();
    signupUser(signupDetails, navigate);
  };

  return (
    <MinDialog isOpen={signupForm} maxWidth="600px">
      <form className="dialog" id={loading && "formLoading"} onSubmit={handleSubmit}>
        <h3>Signup as a Parent then add your kids</h3>
        <div className="dialog__row">
          <div>
            <label htmlFor="">First Name</label>
            <input type="text" name="first_name" value={first_name} onChange={handleChange} required />
          </div>
          <div>
            <label htmlFor="">Last Name</label>
            <input type="text" name="last_name" value={last_name} onChange={handleChange} required />
          </div>
        </div>
        <div className="dialog__row">
          <div>
            <label htmlFor="">Phone Number</label>
            <input type="text" name="phone_number" value={phone_number} onChange={handleChange} minLength="10" maxLength="10" required />
          </div>
          <div>
            <label htmlFor="">Your Password</label>
            <input type="password" name="password" value={password} onChange={handleChange} minLength="4" required />
          </div>
        </div>

        {loading && <CircularProgress style={{ position: "absolute", marginLeft: "45%" }} />}
        <div className="dialog__row">
          <div>
            <label htmlFor="">Repeat Your Password</label>
            <input type="password" name="confirm_password" value={confirm_password} onChange={handleChange} minLength="4" required />
          </div>
        </div>

        <p style={{ textAlign: "center" }}>
          Already a member?{" "}
          <button
            type="button"
            style={{
              background: "transparent",
              color: "dodgerblue",
              fontWeight: "bold",
            }}
            onClick={handleOpenLogin}
          >
            Login Now
          </button>
        </p>
        <div className="form__Buttons">
          <button type="button" className="close__button" onClick={closeSignup}>
            Close
          </button>
          <button type="submit" className="continue__button">
            Signup
          </button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    signupForm: state?.auth?.signupForm,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeSignup: () => dispatch({ type: TOGGLE_SIGNUP, payload: false }),
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    signupUser: (body, navigate) => dispatch(signup_user(body, navigate)),
    openLogin: () => dispatch({ type: TOGGLE_LOGIN, payload: true }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
