import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MinDialog from "../../../../components/MinDialog";
import API from "../../../../shared/axios";
import globals from "../../../../shared/globals";
import { connect } from "react-redux";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import { showError } from "../../../../redux/actions/shared";
import CircularProgress from "@mui/material/CircularProgress";

const RenewSubscription = (props) => {
  const { openRenewSubscription, currentKid, loading, userId } = props;
  const { setOpenRenewSubscription, startLoading, stopLoading } = props;

  const navigate = useNavigate();

  const [paymentDetails, setPaymentDetails] = useState({
    subscription_length: "",
    mpesa_number: "",
  });
  const { subscription_length, mpesa_number } = paymentDetails;
  const [amount, setAmount] = useState("");

  //   we useeffect here to change amount depending on the subscription length selected
  useEffect(() => {
    if (subscription_length === "1_week") {
      setAmount(globals?.oneWeekAmount);
    } else if (subscription_length === "2_weeks") {
      setAmount(globals?.twoWeeksAmount);
    } else if (subscription_length === "3_weeks") {
      setAmount(globals?.threeWeeksAmount);
    } else if (subscription_length === "1_month") {
      setAmount(globals?.monthlyAmount);
    } else {
      setAmount("");
    }
  }, [subscription_length]);

  const handleChange = (e) => {
    setPaymentDetails({ ...paymentDetails, [e.target.name]: e.target.value });
  };

  const handleMpesaRenewal = async (e) => {
    e.preventDefault();
    if (mpesa_number?.trim()?.length !== 10) {
      return window.alert("Mpesa number should be 10 digits");
    }
    startLoading();
    await API.post(
      `/api/subscription/renew-subscription-mpesa-express/${userId}/`,
      { ...paymentDetails, amount, myKidId: currentKid?.id }
    )
      .then((res) => {
        navigate(`/payment-confirmation/${res?.data?.mpesa_payment_id}/`);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  const handlePesapalRenewal = async (e) => {
    e.preventDefault();
    startLoading();
    await API.post(`/api/subscription/renew-subscription-pesapal/${userId}/`, {
      ...paymentDetails,
      amount,
      myKidId: currentKid?.id,
    })
      .then((res) => {
        window.location.replace(
          `/finance/make-payment/${res.data?.payable_amount}/${res.data?.merchant_ref}/`
        );
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <MinDialog isOpen={openRenewSubscription} maxWidth="500px">
      <form
        className="dialog"
        onSubmit={handlePesapalRenewal}
        id={loading && "formLoading"}
      >
        <h3>Renew subscription for {currentKid?.kid_name}</h3>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Subscription length</label>
          <select
            name="subscription_length"
            onChange={handleChange}
            value={subscription_length}
            required
          >
            <option value="">--select--</option>
            <option value="1_week">1 Week</option>
            <option value="2_weeks">2 Weeks</option>
            <option value="3_weeks">3 Weeks</option>
            <option value="1_month">1 Month</option>
          </select>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        {/* <div className="dialog__rowSingleItem">
          <label htmlFor="">Mpesa Number</label>
          <input
            type="text"
            name="mpesa_number"
            onChange={handleChange}
            value={mpesa_number}
            minLength="10"
            maxLength="10"
            required
          />
        </div> */}
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Amount</label>
          <input type="number" value={amount} disabled />
        </div>
        <p style={{ fontSize: "0.9rem" }}>
          <strong>Note:</strong> Payment will be received by Pesapal on behalf
          of AZW Group Ltd
        </p>
        <div className="form__Buttons">
          <button
            className="close__button"
            type="button"
            onClick={() => setOpenRenewSubscription(false)}
          >
            Close
          </button>
          <button className="continue__button" type="submit">
            Continue
          </button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RenewSubscription);
