import React, { useState } from "react";
import UpcomingClasses from "./components/UpcomingClasses";

const Tutoring = () => {
  const [openUpcomingClasses, setOpenUpcomingClasses] = useState(false);

  return (
    <>
      <div className="table__columns">
        <div className="column">
          <h3>Upcoming Classes</h3>
          <div className="buttons">
            <button type="button" onClick={() => setOpenUpcomingClasses(true)}>
              View
            </button>
          </div>
        </div>
      </div>
      {/* child components */}
      {openUpcomingClasses && (
        <UpcomingClasses
          openUpcomingClasses={openUpcomingClasses}
          setOpenUpcomingClasses={setOpenUpcomingClasses}
        />
      )}
    </>
  );
};

export default Tutoring;
