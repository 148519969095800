import React, { useEffect, useState } from "react";
import MinDialog from "../../../../components/MinDialog";
import { connect } from "react-redux";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import API from "../../../../shared/axios";
import { showError } from "../../../../redux/actions/shared";
import globals from "../../../../shared/globals";
import CircularProgress from "@mui/material/CircularProgress";

const BookSessionDirect = (props) => {
  const { openBookSessionDirect, userId, loading } = props;
  const { setOpenBookSessionDirect, startLoading, stopLoading } = props;

  const [sessionDetails, setSessionDetails] = useState({
    date: "",
    time: "",
    session_duration: "",
  });
  const { date, time, session_duration } = sessionDetails;

  const [gradeLevelLearningAreas, setGradeLevelLearningAreas] = useState([]);
  const [gradeLevelLearningAreaId, setGradeLevelLearningAreaId] = useState("");
  const [amount, setAmount] = useState("");
  const startTimesList = [
    { name: "7 AM", value: 7 },
    { name: "8 AM", value: 8 },
    { name: "9 AM", value: 9 },
    { name: "10 AM", value: 10 },
    { name: "11 AM", value: 11 },
    { name: "12 PM", value: 12 },
    { name: "1 PM", value: 13 },
    { name: "2 PM", value: 14 },
    { name: "3 PM", value: 15 },
    { name: "4 PM", value: 16 },
    { name: "5 PM", value: 17 },
    { name: "6 PM", value: 18 },
    { name: "7 PM", value: 19 },
    { name: "8 PM", value: 20 },
    { name: "9 PM", value: 21 },
    { name: "10 PM", value: 22 },
  ];

  //   we useeffect here to change amount depending on the subscription length selected
  useEffect(() => {
    if (session_duration === "1") {
      setAmount(globals?.hourlyTuitionRate * 1);
    } else if (session_duration === "2") {
      setAmount(globals?.hourlyTuitionRate * 2);
    } else if (session_duration === "3") {
      setAmount(globals?.hourlyTuitionRate * 3);
    } else {
      setAmount("");
    }
  }, [session_duration]);

  useEffect(() => {
    if (userId) {
      startLoading();
      const fetchData = async () => {
        await API.get(
          `/api/tuition/student/get-grade-level-learning-areas/${userId}/`
        )
          .then((res) => {
            setGradeLevelLearningAreas(
              res?.data?.grade_level_learning_areas_data
            );
          })
          .catch((err) => {
            showError(err);
          })
          .finally(() => stopLoading());
      };
      fetchData();
    }
  }, [startLoading, stopLoading, userId]);

  const handleChange = (e) => {
    setSessionDetails({ ...sessionDetails, [e.target.name]: e.target.value });
  };

  const addSixHours = () => {
    const dateCopy = new Date();
    dateCopy.setHours(dateCopy.getHours() + 6);
    return dateCopy;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // we must ensure class booking is at least 6 hours before
    const bookedTimeDate = new Date(`${date} ${time}:00`);
    if (bookedTimeDate < new Date()) {
      return window.alert("You cannot book a tutoring session in the past");
    }
    if (bookedTimeDate < addSixHours()) {
      return window.alert(
        "You should schedule a tutoring session at least 6 hours from now"
      );
    }
    startLoading();
    await API.post(
      `/api/tuition/student/maintain-tutoring-sessions/${userId}/`,
      {
        student: userId,
        start_time: `${date} ${time}:00:00`,
        session_duration,
        grade_level_learning_area: gradeLevelLearningAreaId,
        amount,
      }
    )
      .then((res) => {
        window.location.replace(
          `/finance/make-payment/${res.data?.payable_amount}/${res.data?.merchant_ref}/`
        );
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <MinDialog isOpen={openBookSessionDirect} maxWidth="700px">
      <form
        className="dialog"
        onSubmit={handleSubmit}
        id={loading && "formLoading"}
      >
        <h3>Book a tutoring session</h3>

        <div className="dialog__rowSingleItem">
          <label htmlFor="">Learning Area</label>
          <select
            name="gradeLevelLearningAreaId"
            onChange={(e) => setGradeLevelLearningAreaId(e.target.value)}
            value={gradeLevelLearningAreaId}
            required
          >
            <option value="">--select--</option>
            {gradeLevelLearningAreas?.map((item) => (
              <option value={item?.id} key={item?.id}>
                {item?.name}
              </option>
            ))}
          </select>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div className="dialog__row">
          <div>
            <label htmlFor="">Select Date</label>
            <input
              type="date"
              name="date"
              onChange={handleChange}
              value={date}
              required
            />
          </div>
          <div>
            <label htmlFor="">Select Time</label>
            <select name="time" onChange={handleChange} value={time} required>
              <option value="">--select--</option>
              {startTimesList?.map((item) => (
                <option value={item?.value} key={item?.value}>
                  {item?.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="dialog__row">
          <div>
            <label htmlFor="">Duration</label>
            <select
              name="session_duration"
              onChange={handleChange}
              value={session_duration}
              required
            >
              <option value="">--select--</option>
              <option value="1">1 hour</option>
              <option value="2">2 hours</option>
              <option value="3">3 hours</option>
            </select>
          </div>
          <div>
            <label htmlFor="">Amount</label>
            <input type="number" name="" value={amount} disabled required />
          </div>
        </div>
        <div className="form__Buttons">
          <button
            type="button"
            className="close__button"
            onClick={() => setOpenBookSessionDirect(false)}
          >
            Close
          </button>
          <button type="submit" className="continue__button">
            Continue
          </button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookSessionDirect);
