import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../components/MinDialog";
import { showError } from "../../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import API from "../../../../shared/axios";

const EditParentKid = (props) => {
  const {
    openEditParentKid,
    currentParentKid,
    loading,
    userId,
    gradeLevels,
    currentUser,
    kids,
  } = props;
  const {
    setOpenEditParentKid,
    setCurrentParentKid,
    startLoading,
    stopLoading,
    setKids,
  } = props;

  const handleChange = (e) => {
    setCurrentParentKid({
      ...currentParentKid,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    startLoading();
    await API.patch(
      `/api/user/teacher/maintain-parent-kids/${userId}/${currentUser?.id}`,
      currentParentKid
    )
      .then((res) => {
        setKids(
          kids?.map((item) =>
            item?.id === currentParentKid?.id
              ? res?.data?.updated_parent_kid_data
              : item
          )
        );
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <MinDialog isOpen={openEditParentKid} maxWidth="700px">
      <form
        className="dialog"
        id={loading && "formLoading"}
        onSubmit={handleSubmit}
      >
        <h3>Edit kid details</h3>
        <div className="dialog__row">
          <div>
            <label htmlFor="">First Name</label>
            <input
              type="text"
              name="first_name"
              onChange={handleChange}
              value={currentParentKid?.first_name}
              required
            />
          </div>
          <div>
            <label htmlFor="">Last Name</label>
            <input
              type="text"
              name="last_name"
              onChange={handleChange}
              value={currentParentKid?.last_name}
              required
            />
          </div>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div className="dialog__row">
          <div>
            <label htmlFor="">Grade Level</label>
            <select
              name="grade_level"
              value={currentParentKid?.grade_level}
              onChange={handleChange}
              required
            >
              <option value="">--select--</option>
              {gradeLevels?.map((item) => (
                <option value={item?.id} key={item?.id}>
                  {item?.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="form__Buttons">
          <button
            className="close__button"
            type="button"
            onClick={() => setOpenEditParentKid(false)}
          >
            Close
          </button>
          <button className="continue__button" type="submit">
            Submit
          </button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
    gradeLevels: state?.tuition?.gradeLevels,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditParentKid);
