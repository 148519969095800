import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { TOGGLE_LOADING } from "../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";
import API from "../../shared/axios";
import { useParams } from "react-router-dom";
import { showError } from "../../redux/actions/shared";

import "./LiveClass.scss";

const LiveClass = (props) => {
  const { liveClassId } = useParams();
  const { userId, loading } = props;
  const { startLoading, stopLoading } = props;

  const [liveClassDetails, setLiveClassDetails] = useState({});

  useEffect(() => {
    if (userId) {
      startLoading();
      const fetchData = async () => {
        await API.get(
          `/api/tuition/get-live-class-details/${userId}/${liveClassId}/`
        )
          .then((res) => {
            setLiveClassDetails(res?.data?.live_class_details);
          })
          .catch((err) => showError(err))
          .finally(() => stopLoading());
      };
      fetchData();
    }
  }, [userId, startLoading, stopLoading, liveClassId]);

  return (
    <div className="live__class">
      {loading && (
        <CircularProgress style={{ position: "absolute", marginLeft: "45%" }} />
      )}
      {liveClassDetails?.live_class_url && (
        <iframe
          title={`${liveClassDetails?.id}`}
          src={`${liveClassDetails?.live_class_url}`}
          allow="camera;microphone;fullscreen;display-capture"
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveClass);
