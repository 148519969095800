import React, { useEffect, useState } from "react";
import MinDialog from "../../../../components/MinDialog";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import { connect } from "react-redux";
import moment from "moment";
import API from "../../../../shared/axios";
import { showError } from "../../../../redux/actions/shared";
import RenewSubscription from "./RenewSubscription";

const ActiveSubscriptions = (props) => {
  const { openActiveSubscriptions, userId } = props;
  const { setOpenActiveSubscriptions, startLoading, stopLoading } = props;

  const [myKidsList, setMyKidsList] = useState([]);
  const [openRenewSubscription, setOpenRenewSubscription] = useState(false);
  const [currentKid, setCurrentKid] = useState({});

  useEffect(() => {
    if (userId) {
      startLoading();
      const fetchData = async () => {
        await API.get(`/api/subscription/get-my-kids-list/${userId}/`)
          .then((res) => {
            setMyKidsList(res?.data?.my_kids_list_data);
          })
          .catch((err) => showError(err))
          .finally(() => stopLoading());
      };
      fetchData();
    }
  }, [startLoading, stopLoading, userId]);

  const handleOpenRenewSubscription = (kid) => {
    setCurrentKid(kid);
    setOpenRenewSubscription(true);
  };

  return (
    <>
      <MinDialog isOpen={openActiveSubscriptions} maxWidth="700px">
        <div className="dialog">
          <h3>My Kids Subscriptions</h3>
          {myKidsList?.length > 0 ? (
            <table className="table__listing" rules="all">
              <tr className="table__listingHeader">
                <th>Name</th>
                <th className="tc">Grade</th>
                <th className="tc">Expiry Date</th>
              </tr>
              {myKidsList?.map((item) => (
                <tr className="table__listingItem" key={item?.id}>
                  <td data-label="Name">{item?.kid_name}</td>
                  <td className="tc" data-label="Grade">
                    {item?.grade_level_name}
                  </td>
                  <td className="tc" data-label="Expiry Date">
                    {item?.is_expired && (
                      <span
                        className="dodgerblue button__sp"
                        onClick={() => handleOpenRenewSubscription(item)}
                      >
                        Renew
                      </span>
                    )}
                    {!item?.is_expired && (
                      <span>{moment(item?.expiry_date).format("LL")}</span>
                    )}
                  </td>
                </tr>
              ))}
            </table>
          ) : (
            <h4 className="not__available">No subscriptions found</h4>
          )}
          <div className="form__Buttons">
            <button
              className="close__button"
              type="button"
              onClick={() => setOpenActiveSubscriptions(false)}
            >
              Close
            </button>
          </div>
        </div>
      </MinDialog>
      {openRenewSubscription && (
        <RenewSubscription
          openRenewSubscription={openRenewSubscription}
          setOpenRenewSubscription={setOpenRenewSubscription}
          currentKid={currentKid}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActiveSubscriptions);
