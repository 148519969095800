import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import parse from "html-react-parser";
import { showError } from "../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../redux/actions/types";
import API from "../../../shared/axios";
import "./TestExamPage.scss";
import CircularProgress from "@mui/material/CircularProgress";

const TestExamPage = (props) => {
  const { userId, loading } = props;
  const { startLoading, stopLoading } = props;
  const { testExamId } = useParams();
  const navigate = useNavigate();

  const [examTitle, setExamTitle] = useState("");
  const [questions, setQuestions] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [percentScore, setPercentScore] = useState(0);

  useEffect(() => {
    if (userId) {
      startLoading();
      const fetchData = async () => {
        await API.get(`/api/tuition/student/take-test-exam/${userId}/${testExamId}/`)
          .then((res) => {
            setExamTitle(res?.data?.test_exam_data?.title);
            setQuestions(res?.data?.test_exam_data?.questions);
            setSelectedAnswers(res?.data?.test_exam_data?.questions?.reduce((acc, item) => ({ ...acc, [item?.id]: [] }), {}));
          })
          .catch((err) => {
            showError(err);

            navigate("/test-exams/", { replace: true });
          })
          .finally(() => stopLoading());
      };
      fetchData();
    }
  }, [userId, startLoading, stopLoading, testExamId, navigate]);

  const handleChange = (questionId, answer_type, choiceName) => {
    setSelectedAnswers((prev) => {
      const newAnswers = { ...prev };
      if (newAnswers[questionId].includes(choiceName)) {
        newAnswers[questionId] = newAnswers[questionId].filter((item) => item !== choiceName);
      } else if (answer_type === "multiple_answer") {
        newAnswers[questionId] = [...newAnswers[questionId], choiceName];
      } else {
        // means it is a single answer question thus we have to overwrite everything
        newAnswers[questionId] = [choiceName];
      }
      return newAnswers;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (questions?.filter((item) => item?.student_answer === "")?.length !== 0) {
      return window.alert("You must answer all questions");
    }
    startLoading();

    let score = 0;
    for (const questionId in selectedAnswers) {
      let currentQuestionScore = 0;
      const currentQuestion = questions.find((item) => Number(item?.id) === Number(questionId));

      const submittedAnswers = selectedAnswers[questionId];
      for (const item of submittedAnswers) {
        if (currentQuestion?.correct_answers?.includes(item)) {
          currentQuestionScore += currentQuestion?.answer_type === "multiple_answer" ? 1 / currentQuestion.correct_answers.length : 1;
        } else if (!currentQuestion?.correct_answers?.includes(item) && currentQuestion?.answer_type === "multiple_answer") {
          // penalize wrong answers selected if question is a multiple answer question
          currentQuestionScore -= 1 / currentQuestion.correct_answers.length;
        }
      }
      // cap the wrong answers to only affect the current question
      score += Math.max(0, currentQuestionScore);
    }

    // we need to cap the values below to 100%
    const percent_score = Math.min(Math.ceil((score / questions?.length) * 100), 100);

    await API.post(`/api/tuition/student/take-test-exam/${userId}/${testExamId}/`, {
      test_exam: testExamId,
      // we only need the question id and the student_answer
      selected_answers: selectedAnswers,
      marks_scored: percent_score,
    })
      .then((res) => {
        setPercentScore(res?.data?.user_exam_results?.marks_scored);
        setQuestions(res?.data?.user_exam_results?.questions);
        setShowResults(true);
        window.alert(res?.data?.detail);
      })
      .catch((err) => {
        showError(err);
      })
      .finally(() => stopLoading());
  };

  console.log(percentScore);

  return (
    <form className="test__examPage" onSubmit={handleSubmit} id={loading && "pageLoading"}>
      <h3 className="test-title">
        {examTitle}
        {showResults && (
          <span>
            {" "}
            - Your Score: <span className="text-green-600">{percentScore}%</span>
          </span>
        )}
      </h3>
      {loading && <CircularProgress style={{ position: "absolute", marginLeft: "45%" }} />}

      {questions?.map((question, index) => (
        <>
          <h4 className="text-lg font-bold">Question: {index + 1}</h4>
          <p>{parse(`${question?.details}`)}</p>
          <div className="choices">
            <h4>Choices</h4>
            <ul className="choices-ul">
              {Object.entries(question.answer_options)?.map(([choiceKey, choice]) => (
                <li key={`${question?.id}-${choiceKey}`} className={showResults && "show-results"}>
                  <label
                    className={
                      // color green for the correct answers that the user also selected
                      (showResults &&
                        selectedAnswers[question?.id].includes(choiceKey) &&
                        question.correct_answers?.includes(choiceKey) &&
                        "bg-green-400") ||
                      // color red for the wrong answers a user has selected
                      (showResults &&
                        selectedAnswers[question?.id].includes(choiceKey) &&
                        !question.correct_answers?.includes(choiceKey) &&
                        "bg-red-400") ||
                      // color for correct answers that the user never selected
                      (showResults && !selectedAnswers[question?.id].includes(choiceKey) && question.correct_answers?.includes(choiceKey) && "bg-teal-300")
                    }
                  >
                    <input
                      type={question.answer_type === "multiple_answer" ? "checkbox" : "radio"}
                      name={`${question.id}`}
                      value={choiceKey}
                      checked={selectedAnswers[question?.id].includes(choiceKey)}
                      onChange={() => handleChange(question.id, question.answer_type, choiceKey)}
                      onClick={(e) => showResults && e.preventDefault()} // to disable checking/unchecking after result submission
                    />
                    <span className="pl-1">{parse(`${choice.contents}`)}</span>
                  </label>
                </li>
              ))}
            </ul>
            {showResults && (
              <div className="explanation">
                <h3>Explanation:</h3>
                <p>{parse(`${question?.explanation}`)}</p>
              </div>
            )}
          </div>
          <br />
        </>
      ))}
      <br />
      {!showResults && (
        <button type="submit" className="add-button">
          Finish Test
        </button>
      )}
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TestExamPage);
