export const TOGGLE_LOGIN = "TOGGLE_LOGIN";
export const TOGGLE_SIGNUP = "TOGGLE_SIGNUP";
export const TOGGLE_LOADING = "TOGGLE_LOADING";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const TOGGLE_CHANGE_PASSWORD = "TOGGLE_CHANGE_PASSWORD";
export const RESET_AUTH_STATE = "RESET_AUTH_STATE";
export const RESET_SHARED_STATE = "RESET_SHARED_STATE";
export const SET_GRADE_LEVELS = "SET_GRADE_LEVELS";
export const SET_MY_LEARNING_AREAS = "SET_MY_LEARNING_AREAS";
export const SET_GRADE_LEVEL_LEARNING_AREAS = "SET_GRADE_LEVEL_LEARNING_AREAS";
export const SET_MY_KIDS = "SET_MY_KIDS";
export const ADD_KID = "ADD_KID";
export const EDIT_KID = "EDIT_KID";
