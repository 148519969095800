import { connect } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { logout } from "../../redux/actions/auth";

import CommonLinks from "./Links/CommonLinks";
import ParentLinks from "./Links/ParentLinks";
import StaffLinks from "./Links/StaffLinks";
import StudentLinks from "./Links/StudentLinks";
import TeacherLinks from "./Links/TeacherLinks";
import "./Sidebar.scss";
import SystemAdminLinks from "./Links/SystemAdminLinks";

const Sidebar = (props) => {
  const { users_groups, logoutUser, loggedIn } = props;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  return (
    <div className="left-navbar" id="nav-bar">
      <nav className="nav">
        <div className="nav__list">
          {/* unprotected links */}
          <>
            <CommonLinks pathname={pathname} Link={Link} />
            {/* protected links */}
            {["Parent"]?.some((allowed_group) =>
              users_groups?.includes(allowed_group)
            ) && (
              <>
                <ParentLinks pathname={pathname} Link={Link} />
              </>
            )}
            {["Teacher"]?.some((allowed_group) =>
              users_groups?.includes(allowed_group)
            ) && (
              <>
                <TeacherLinks pathname={pathname} Link={Link} />
              </>
            )}
            {["Student"]?.some((allowed_group) =>
              users_groups?.includes(allowed_group)
            ) && (
              <>
                <StudentLinks pathname={pathname} Link={Link} />
              </>
            )}
            {["Staff"]?.some((allowed_group) =>
              users_groups?.includes(allowed_group)
            ) && (
              <>
                <StaffLinks pathname={pathname} Link={Link} />
              </>
            )}
            {["System Admin"]?.some((allowed_group) =>
              users_groups?.includes(allowed_group)
            ) && (
              <>
                <SystemAdminLinks pathname={pathname} Link={Link} />
              </>
            )}
            {loggedIn && (
              <>
                <Link
                  to="/profile/"
                  className={
                    `${pathname}` === "/profile/"
                      ? "nav__link active"
                      : "nav__link"
                  }
                >
                  <i class="bx bx-user nav__icon"></i>
                  <span className="nav__name">Profile</span>
                </Link>
                <Link
                  to="#"
                  className="nav__link"
                  onClick={() => logoutUser(navigate)}
                >
                  <i className="bx bx-log-out-circle" title="Logout"></i>
                  <span className="nav__name">Logout</span>
                </Link>
              </>
            )}
          </>
        </div>
      </nav>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    users_groups: state?.auth?.user?.users_groups,
    loggedIn: state?.auth?.loggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: (navigate) => dispatch(logout(navigate)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
