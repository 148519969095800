// import installed packages
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import styles

import "./Header.scss";
// import material ui items
// import shared/global items
import { showNavbar } from "../../shared/scripts";
// import components/pages

// import redux API
import { TOGGLE_LOGIN, TOGGLE_SIGNUP } from "../../redux/actions/types";
import Login from "../users/Login/Login";
import Signup from "../users/Signup";

const Header = (props) => {
  const { first_name, loginForm, signupForm, loggedIn } = props;
  const { openLogin, openSignup } = props;

  return (
    <>
      <header className="header" id="header">
        <div className="header__left">
          <i
            className="bx bx-menu showBtn"
            id="mobile__menuBtnShow"
            onClick={showNavbar}
          ></i>
          <i
            className="bx bx-window-close hideBtn red"
            onClick={showNavbar}
            id="mobile__menuBtnHide"
          ></i>

          <h1>
            <Link to="/" className="font-bold">
              Mkufunzi
            </Link>
          </h1>
        </div>
        {loggedIn && (
          <div className="header__right authenticated">
            <>
              <div>
                <span>
                  <span className="green">&bull;</span> {first_name}
                </span>
              </div>
            </>
          </div>
        )}{" "}
        {!loggedIn && (
          <div className="header__right">
            <span className="button" onClick={openLogin}>
              Login
            </span>
            <span className="button" onClick={openSignup}>
              Signup
            </span>
          </div>
        )}
      </header>
      {/* components */}
      {loginForm && <Login />}
      {signupForm && <Signup />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    first_name: state.auth.user?.first_name,
    loginForm: state.auth?.loginForm,
    signupForm: state.auth?.signupForm,
    loggedIn: state.auth?.loggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openLogin: () => dispatch({ type: TOGGLE_LOGIN, payload: true }),
    openSignup: () => dispatch({ type: TOGGLE_SIGNUP, payload: true }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
