import React, { useState } from "react";
import TestExamsDone from "./components/TestExamsDone";
import AvailableTestExams from "./components/AvailableTestExams";
import MyPerformance from "./components/MyPerformance";
import BookedSessions from "./components/BookedSessions";

const StudentTestExams = () => {
  const [openAvailableTestExams, setOpenAvailableTestExams] = useState(false);
  const [openTestExamsDone, setOpenTestExamsDone] = useState(false);
  const [openMyPerformance, setOpenMyPerformance] = useState(false);
  const [openBookedSessions, setOpenBookedSessions] = useState(false);

  return (
    <>
      <div className="dialog">
        <div className="table__columns">
          <div className="column">
            <h3>Available Test Exams</h3>
            <div className="buttons">
              <button type="button" onClick={() => setOpenAvailableTestExams(true)}>
                Access Now
              </button>
            </div>
          </div>
          <div className="column">
            <h3>Test Exams Done</h3>
            <div className="buttons">
              <button type="button" onClick={() => setOpenTestExamsDone(true)}>
                View Now
              </button>
            </div>
          </div>
          <div className="column">
            <h3>My Performance</h3>
            <div className="buttons">
              <button type="button" onClick={() => setOpenMyPerformance(true)}>
                View Now
              </button>
            </div>
          </div>
          {/* <div className="column">
            <h3>Get Tutoring</h3>
            <div className="buttons">
              <button type="button" onClick={() => setOpenBookedSessions(true)}>
                View &amp; Book
              </button>
            </div>
          </div> */}
        </div>
      </div>
      {openAvailableTestExams && (
        <AvailableTestExams openAvailableTestExams={openAvailableTestExams} setOpenAvailableTestExams={setOpenAvailableTestExams} />
      )}
      {openTestExamsDone && <TestExamsDone openTestExamsDone={openTestExamsDone} setOpenTestExamsDone={setOpenTestExamsDone} />}
      {openMyPerformance && <MyPerformance openMyPerformance={openMyPerformance} setOpenMyPerformance={setOpenMyPerformance} />}
      {openBookedSessions && <BookedSessions openBookedSessions={openBookedSessions} setOpenBookedSessions={setOpenBookedSessions} />}
    </>
  );
};

export default StudentTestExams;
