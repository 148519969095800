import React from "react";
import { connect } from "react-redux";
import { get_user_data } from "../../../../redux/actions/auth";
import { useNavigate } from "react-router-dom";
import { showError } from "../../../../redux/actions/shared";
import CircularProgress from "@mui/material/CircularProgress";
import { TOGGLE_LOADING, TOGGLE_LOGIN } from "../../../../redux/actions/types";

import API from "../../../../shared/axios";
import MinDialog from "../../../MinDialog";

const SelectLoginUser = (props) => {
  const { openSelectLoginUser, studentsAvailable, parentUser, loading } = props;
  const { setOpenSelectLoginUser, startLoading, stopLoading, closeLogin, getUserData } = props;

  const navigate = useNavigate();

  const handleSubmit = async (itemId, userType) => {
    startLoading();
    await API.get(`/api/user/select-user-to-login-with/${itemId}/`)
      .then((res) => {
        localStorage.setItem("access_token", res?.data?.access_token);
        closeLogin();
        getUserData(navigate);
        if (userType === "student") {
          navigate("/test-exams/");
        } else if (userType === "parent") {
          navigate("/my-kids/");
        }
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <MinDialog isOpen={openSelectLoginUser}>
      <div className="dialog" id={loading && "formLoading"}>
        <h3>Select the user to login with</h3>
        {loading && <CircularProgress style={{ position: "absolute", marginLeft: "45%" }} />}
        <table className="table__listing" rules="all" border="1">
          <tr className="table__listingHeader">
            <th colSpan="2">Parent Account</th>
          </tr>

          <tbody>
            <tr className="table__listingItem dodgerblue button__sp" onClick={() => handleSubmit(parentUser?.id, "parent")}>
              <td colSpan="2" data-label="Parent Account">
                {parentUser?.first_name} {parentUser?.last_name} {parentUser?.phone_number}
              </td>
            </tr>
          </tbody>
          <br />

          <tr className="table__listingHeader">
            <th colSpan="2">Students Accounts</th>
          </tr>

          <tbody>
            {studentsAvailable?.map((item) => (
              <tr className="table__listingItem dodgerblue button__sp" key={item?.id} onClick={() => handleSubmit(item?.id, "student")}>
                <td colSpan="1" data-label="Student Account">
                  {item?.first_name} {item?.last_name}
                </td>
                <td colSpan="1">{item?.grade_level_name}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="form__Buttons">
          <button type="button" className="close__button" onClick={() => setOpenSelectLoginUser(false)}>
            Close
          </button>
        </div>
      </div>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
    closeLogin: () => dispatch({ type: TOGGLE_LOGIN, payload: false }),
    getUserData: (navigate) => dispatch(get_user_data(navigate)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectLoginUser);
