// import installed packages
import { useEffect, useState } from "react";
import { connect } from "react-redux";

// import styles
import "./Profile.scss";
// import material ui items
import CircularProgress from "@mui/material/CircularProgress";
// import shared/global items
// import components/pages
import ChangePassword from "../../components/users/ChangePassword";
// import redux API
import {
  TOGGLE_CHANGE_PASSWORD,
  TOGGLE_LOADING,
} from "../../redux/actions/types";
import { update_user } from "../../redux/actions/auth";
import { get_grade_levels } from "../../redux/actions/tuition";

const Profile = (props) => {
  const {
    first_name,
    last_name,
    phone_number,
    userId,
    loading,
    users_groups,
    grade_level,
    gradeLevels,
  } = props; // extract state from props
  const { startLoading, updateUser, openChangePassword, getGradeLevels } =
    props; // extract dispatch actions from props
  // internal state
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    setUserDetails({
      first_name,
      last_name,
      phone_number,
      grade_level,
    });
  }, [phone_number, first_name, last_name, grade_level]);

  useEffect(() => {
    if (userId && gradeLevels?.length === 0) {
      startLoading();
      getGradeLevels(userId);
    }
  }, [userId, getGradeLevels, startLoading, gradeLevels?.length]);

  // handle change function
  const handleChange = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };

  // function to update user details
  const updateUserDetails = (e) => {
    e.preventDefault();
    startLoading();
    // call the signup action creator
    updateUser(userId, userDetails);
  };

  return (
    <form
      className="profile"
      id={loading ? "formLoading" : ""}
      onSubmit={updateUserDetails}
    >
      <h3>Profile Details</h3>
      {loading && (
        <CircularProgress style={{ position: "absolute", marginLeft: "10%" }} />
      )}
      <div className="profile__row">
        <div>
          <label htmlFor="">First Name</label>
          <input
            type="text"
            name="first_name"
            value={userDetails.first_name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="">Last Name</label>
          <input
            type="text"
            name="last_name"
            value={userDetails.last_name}
            onChange={handleChange}
            required
          />
        </div>
      </div>
      <div className="profile__row">
        {/* phone number is NOT applicable for students */}
        {["Teacher", "Parent", "Staff"]?.some((allowed_group) =>
          users_groups?.includes(allowed_group)
        ) && (
          <div>
            <label htmlFor="">Phone Number</label>
            <input
              type="text"
              name="phone_number"
              value={userDetails.phone_number}
              onChange={handleChange}
              required
            />
          </div>
        )}
        {/* grade level is applicable for students */}
        {["Student"]?.some((allowed_group) =>
          users_groups?.includes(allowed_group)
        ) && (
          <div>
            <label htmlFor="">Grade Level</label>
            <select
              name="grade_level"
              onChange={handleChange}
              value={userDetails?.grade_level}
              required
            >
              <option value="">--select--</option>
              {gradeLevels?.map((item) => (
                <option value={item?.id} key={item?.id}>
                  {item?.name}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
      <div className="profile__Buttons">
        <button type="submit" className="update">
          Update Profile
        </button>
        {/* we only allow the users within the listed groups to change their password i.e we do not allow students */}
        {["Teacher", "Parent", "Staff"]?.some((allowed_group) =>
          users_groups?.includes(allowed_group)
        ) && (
          <button
            type="button"
            className="change__password"
            onClick={openChangePassword}
          >
            Change Password
          </button>
        )}
      </div>

      {/* linked components */}
      <ChangePassword />
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    first_name: state.auth.user?.first_name,
    last_name: state.auth.user?.last_name,
    phone_number: state.auth.user?.phone_number,
    grade_level: state.auth.user?.grade_level,
    userId: state.auth.user?.id,
    users_groups: state.auth.user?.users_groups,
    loading: state.shared?.loading,
    gradeLevels: state?.tuition?.gradeLevels,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    updateUser: (userId, userDetails) =>
      dispatch(update_user(userId, userDetails)),
    openChangePassword: () =>
      dispatch({ type: TOGGLE_CHANGE_PASSWORD, payload: true }),
    getGradeLevels: (userId) => dispatch(get_grade_levels(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
