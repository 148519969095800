import React from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../components/MinDialog";
import CircularProgress from "@mui/material/CircularProgress";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import { update_kid } from "../../../../redux/actions/auth";

const EditKid = (props) => {
  const { openEditKid, loading, userId, currentKid, gradeLevels } = props;
  const { setOpenEditKid, startLoading, setCurrentKid, updateKid } = props;

  const handleChange = (e) => {
    setCurrentKid({ ...currentKid, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    startLoading();
    updateKid(userId, currentKid);
  };

  return (
    <MinDialog isOpen={openEditKid} maxWidth="600px">
      <form
        className="dialog"
        onSubmit={handleSubmit}
        id={loading && "formLoading"}
      >
        <h3>Edit kid details</h3>
        <div className="dialog__row">
          <div>
            <label htmlFor="">First Name</label>
            <input
              type="text"
              name="first_name"
              onChange={handleChange}
              value={currentKid?.first_name}
              required
            />
          </div>
          <div>
            <label htmlFor="">Last Name</label>
            <input
              type="text"
              name="last_name"
              onChange={handleChange}
              value={currentKid?.last_name}
              required
            />
          </div>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div className="dialog__row">
          <div>
            <label htmlFor="">Grade Level</label>
            <select
              name="grade_level"
              onChange={handleChange}
              value={currentKid?.grade_level}
              required
            >
              <option value="">--select--</option>
              {gradeLevels?.map((item) => (
                <option value={item?.id} key={item?.id}>
                  {item?.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="form__Buttons">
          <button
            className="close__button"
            type="button"
            onClick={() => setOpenEditKid(false)}
          >
            Close
          </button>
          <button type="submit" className="continue__button">
            Update
          </button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
    gradeLevels: state?.tuition?.gradeLevels,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
    updateKid: (userId, body) => dispatch(update_kid(userId, body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditKid);
