import API from "../shared/axios";

export const getUserData = () => API.get(`/api/user/get-user-data/`);

export const teacherGetGradeLevels = (userId) =>
  API.get(`/api/tuition/teacher/teacher-get-grade-levels/${userId}/`);

export const getGradeLevels = (userId) =>
  API.get(`/api/tuition/get-grade-levels/${userId}/`);

export const getMyLearningAreas = (userId) =>
  API.get(`/api/tuition/teacher/get-my-learning-areas/${userId}/`);

export const getAllGradeLevelLearningAreas = (userId) =>
  API.get(`/api/tuition/staff/get-all-grade-level-learning-areas/${userId}/`);

export const getMyKids = (userId) =>
  API.get(`/api/user/parent/maintain-my-kids/${userId}/`);
