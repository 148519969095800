import React from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../components/MinDialog";
import { showError } from "../../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";
import API from "../../../../shared/axios";

const EditParent = (props) => {
  const { openEditParent, currentUser, parentsList, loading, userId } = props;
  const {
    setOpenEditParent,
    setCurrentUser,
    setParentsList,
    startLoading,
    stopLoading,
  } = props;

  const handleChange = (e) => {
    setCurrentUser({ ...currentUser, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    startLoading();
    await API.patch(`/api/user/teacher/maintain-parents/${userId}`, currentUser)
      .then((res) => {
        setParentsList(
          parentsList?.map((item) =>
            item?.id === currentUser?.id ? res?.data?.updated_parent_data : item
          )
        );
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <MinDialog isOpen={openEditParent} maxWidth="700px">
      <form
        className="dialog"
        id={loading && "formLoading"}
        onSubmit={handleSubmit}
      >
        <h3>Edit parent details</h3>
        <div className="dialog__row">
          <div>
            <label htmlFor="">First Name</label>
            <input
              type="text"
              name="first_name"
              onChange={handleChange}
              value={currentUser?.first_name}
              required
            />
          </div>
          <div>
            <label htmlFor="">Last Name</label>
            <input
              type="text"
              name="last_name"
              onChange={handleChange}
              value={currentUser?.last_name}
              required
            />
          </div>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div className="dialog__row">
          <div>
            <label htmlFor="">Phone Number</label>
            <input
              type="text"
              name="phone_number"
              onChange={handleChange}
              value={currentUser?.phone_number}
              minLength="10"
              maxLength="10"
              required
            />
          </div>
        </div>
        <div className="form__Buttons">
          <button
            className="close__button"
            type="button"
            onClick={() => setOpenEditParent(false)}
          >
            Close
          </button>
          <button className="continue__button" type="submit">
            Submit
          </button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditParent);
