import { connect } from "react-redux";
import { TOGGLE_LOGIN, TOGGLE_SIGNUP } from "../../../redux/actions/types";

const CommonLinks = (props) => {
  const { Link, pathname, loggedIn } = props;
  const { openLogin, openSignup, closeLogin, closeSignup } = props;

  const handleOpenLogin = () => {
    openLogin();
    closeSignup();
  };

  const handleOpenSignup = () => {
    openSignup();
    closeLogin();
  };

  return (
    <>
      <Link
        to="/"
        className={`${pathname}` === "/" ? "nav__link active" : "nav__link"}
      >
        <i className="bx bxs-home"></i>
        <span className="nav__name">Home</span>
      </Link>

      {!loggedIn && (
        <>
          <Link to="#" className="nav__link" onClick={handleOpenLogin}>
            <i className="bx bx-log-in-circle"></i>
            Login
          </Link>
          <Link to="#" className="nav__link" onClick={handleOpenSignup}>
            <i className="bx bxs-log-in-circle"></i>
            Sign Up
          </Link>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedIn: state?.auth?.loggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openLogin: () => dispatch({ type: TOGGLE_LOGIN, payload: true }),
    openSignup: () => dispatch({ type: TOGGLE_SIGNUP, payload: true }),
    closeLogin: () => dispatch({ type: TOGGLE_LOGIN, payload: false }),
    closeSignup: () => dispatch({ type: TOGGLE_SIGNUP, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommonLinks);
