import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import MinDialog from "../../../../components/MinDialog";
import { showError } from "../../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import API from "../../../../shared/axios";

const EditTestExam = (props) => {
  const { openEditTestExam, testExamId, userId, loading, testExamsList, myLearningAreas } = props;
  const { setOpenEditTestExam, startLoading, stopLoading, setTestExamsList } = props;

  const [testExamDetails, setTestExamDetails] = useState({});
  const { title, grade_level_learning_area, topic } = testExamDetails;
  const [topicsList, setTopicsList] = useState([]);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (userId) {
      const fetchData = async () => {
        await API.get(`/api/tuition/teacher/get-test-exam-details/${userId}/${testExamId}/`)
          .then((res) => {
            setTestExamDetails(res?.data?.test_exam_details);
            setIsActive(res?.data?.test_exam_details?.is_active);
          })
          .catch((err) => showError(err))
          .finally(() => stopLoading());
      };
      fetchData();
    }
  }, [userId, startLoading, stopLoading, testExamId]);

  useEffect(() => {
    if (userId && grade_level_learning_area) {
      const fetchData = async () => {
        await API.get(`/api/tuition/staff/maintain-topics/${userId}/${grade_level_learning_area}/`)
          .then((res) => {
            setTopicsList(res?.data?.topics_data);
          })
          .catch((err) => showError(err))
          .finally(() => {});
      };
      fetchData();
    }
  }, [userId, grade_level_learning_area]);

  const handleChange = (e) => {
    setTestExamDetails({
      ...testExamDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    startLoading();
    await API.patch(`/api/tuition/teacher/maintain-test-exams/${userId}/`, {
      testExamId,
      title,
      topic,
      is_active: isActive,
    })
      .then((res) => {
        setTestExamsList(testExamsList?.map((item) => (item?.id === testExamId ? res?.data?.updated_test_exam_data : item)));

        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <MinDialog isOpen={openEditTestExam} maxWidth="500px">
      <form className="dialog" id={loading && "formLoading"} onSubmit={handleSubmit}>
        <h3>Test Exam Details</h3>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Learning Area</label>
          <select name="grade_level_learning_area" onChange={handleChange} value={grade_level_learning_area} required>
            <option value="">--select--</option>
            {myLearningAreas?.map((item) => (
              <option value={item?.id} key={item?.id}>
                {item?.name}
              </option>
            ))}
          </select>
        </div>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Topic</label>
          <select name="topic" onChange={handleChange} value={topic} required>
            <option value="">--select--</option>
            {topicsList?.map((item) => (
              <option value={item?.id} key={item?.id}>
                {item?.title}
              </option>
            ))}
          </select>
        </div>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Test Exam title</label>
          <input type="text" name="title" onChange={handleChange} value={title} required />
        </div>
        {loading && <CircularProgress style={{ position: "absolute", marginLeft: "45%" }} />}

        <div style={{ marginLeft: "25px" }}>
          <input type="checkbox" checked={isActive} id="isChecked" onClick={() => setIsActive(!isActive)} />
          <label htmlFor="isChecked" style={{ marginLeft: "10px", color: "green", fontWeight: "bold" }}>
            Tick here to activate this test exam
          </label>
        </div>
        <div className="form__Buttons">
          <button type="button" onClick={() => setOpenEditTestExam(false)} className="close__button">
            Close
          </button>
          <button type="submit" className="continue-button">
            Update
          </button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
    myLearningAreas: state?.tuition?.myLearningAreas,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTestExam);
