import React, { useState } from "react";
import TeachersList from "./components/Teachers";

const Teachers = () => {
  const [openTeachers, setOpenTeachers] = useState(false);
  return (
    <>
      <div className="table__columns">
        <div className="column">
          <h3>Teachers</h3>
          <div className="buttons">
            <button type="button" onClick={() => setOpenTeachers(true)}>
              view &amp; update
            </button>
          </div>
        </div>
      </div>
      {/* child components */}
      {openTeachers && (
        <TeachersList
          openTeachers={openTeachers}
          setOpenTeachers={setOpenTeachers}
        />
      )}
    </>
  );
};

export default Teachers;
