import React, { useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../components/MinDialog";
import { showError } from "../../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";
import API from "../../../../shared/axios";

const AddParent = (props) => {
  const { openAddParent, parentsList, loading, userId } = props;
  const { setOpenAddParent, setParentsList, startLoading, stopLoading } = props;

  const [newUser, setNewUser] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    password: "",
    confirm_password: "",
  });

  const handleChange = (e) => {
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newUser?.password?.trim() !== newUser?.confirm_password?.trim()) {
      return window.alert("Error, password and confirm password must match");
    }
    startLoading();
    await API.post(`/api/user/teacher/maintain-parents/${userId}`, newUser)
      .then((res) => {
        setParentsList([...parentsList, res?.data?.new_parent_data]);
        setNewUser({
          first_name: "",
          last_name: "",
          phone_number: "",
          password: "",
          confirm_password: "",
        });
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <MinDialog isOpen={openAddParent} maxWidth="700px">
      <form
        className="dialog"
        id={loading && "formLoading"}
        onSubmit={handleSubmit}
      >
        <h3>Add new parent</h3>
        <div className="dialog__row">
          <div>
            <label htmlFor="">First Name</label>
            <input
              type="text"
              name="first_name"
              onChange={handleChange}
              value={newUser?.first_name}
              required
            />
          </div>
          <div>
            <label htmlFor="">Last Name</label>
            <input
              type="text"
              name="last_name"
              onChange={handleChange}
              value={newUser?.last_name}
              required
            />
          </div>
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div className="dialog__row">
          <div>
            <label htmlFor="">Phone Number</label>
            <input
              type="text"
              name="phone_number"
              onChange={handleChange}
              value={newUser?.phone_number}
              minLength="10"
              maxLength="10"
              required
            />
          </div>
        </div>
        <div className="dialog__row">
          <div>
            <label htmlFor="">Password</label>
            <input
              type="password"
              name="password"
              onChange={handleChange}
              value={newUser?.password}
              minLength="6"
              required
            />
          </div>
          <div>
            <label htmlFor="">Confirm Password</label>
            <input
              type="password"
              name="confirm_password"
              onChange={handleChange}
              value={newUser?.confirm_password}
              minLength="6"
              required
            />
          </div>
        </div>
        <div className="form__Buttons">
          <button
            className="close__button"
            type="button"
            onClick={() => setOpenAddParent(false)}
          >
            Close
          </button>
          <button className="continue__button" type="submit">
            Submit
          </button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddParent);
