import React, { useState } from "react";
import GradeLevelLearningAreas from "./components/GradeLevelLearningAreas";

const AcademicsManagement = () => {
  const [openGradeLevelLearningAreas, setOpenGradeLevelLearningAreas] =
    useState(false);
  return (
    <>
      <div className="table__columns">
        <div className="column">
          <h3>Grade Level Learning Areas</h3>
          <div className="buttons">
            <button
              type="button"
              onClick={() => setOpenGradeLevelLearningAreas(true)}
            >
              view &amp; update
            </button>
          </div>
        </div>
      </div>
      {/* child components */}
      {openGradeLevelLearningAreas && (
        <GradeLevelLearningAreas
          openGradeLevelLearningAreas={openGradeLevelLearningAreas}
          setOpenGradeLevelLearningAreas={setOpenGradeLevelLearningAreas}
        />
      )}
    </>
  );
};

export default AcademicsManagement;
