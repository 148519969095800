import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../components/MinDialog";
import { showError } from "../../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";
import API from "../../../../shared/axios";
import EditParentKid from "./EditParentKid";

const MyStudents = (props) => {
  const { openMyStudents, gradeLevels, userId, loading } = props;
  const { setOpenMyStudents, startLoading, stopLoading } = props;

  const [studentsList, setStudentsList] = useState([]);
  const [gradeLevelId, setGradeLevelId] = useState("");
  const [openEditParentKid, setOpenEditParentKid] = useState(false);
  const [currentParentKid, setCurrentParentKid] = useState({});

  useEffect(() => {
    if (userId && gradeLevelId) {
      startLoading();
      const fetchData = async () => {
        await API.get(
          `/api/user/teacher/get-grade-level-students/${userId}/${gradeLevelId}/`
        )
          .then((res) => {
            setStudentsList(res?.data?.students_data);
          })
          .catch((err) => showError(err))
          .finally(() => stopLoading());
      };
      fetchData();
    }
  }, [userId, gradeLevelId, startLoading, stopLoading]);

  const handleOpenEditParentKid = (item) => {
    setCurrentParentKid(item);
    setOpenEditParentKid(true);
  };

  const handleDeleteKid = async (kidId) => {
    if (
      window.confirm(
        `Student data cannot be recovered after deleting. Do you want to continue?`
      )
    ) {
      startLoading();
      await API.delete(
        `/api/user/teacher/delete-parent-kid/${userId}/${kidId}/`
      )
        .then((res) => {
          setStudentsList(studentsList?.filter((item) => item?.id !== kidId));
          window.alert(res?.data?.detail);
        })
        .catch((err) => showError(err))
        .finally(() => stopLoading());
    }
  };

  return (
    <>
      <MinDialog isOpen={openMyStudents} maxWidth="700px">
        <form className="dialog" id={loading && "formLoading"}>
          <h3>Select grade level to view students</h3>
          <div className="dialog__rowSingleItem">
            <select
              name=""
              onChange={(e) => setGradeLevelId(e.target.value)}
              value={gradeLevelId}
              required
            >
              <option value="">--select--</option>
              {gradeLevels?.map((item) => (
                <option value={item?.id} key={item?.id}>
                  {item?.name}
                </option>
              ))}
            </select>
          </div>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "45%" }}
            />
          )}
          {studentsList?.length > 0 && (
            <table className="table__listing" rules="all">
              <thead>
                <tr className="table__listingHeader">
                  <th>Name</th>
                  <th className="tc">Edit</th>
                  <th className="tc">Delete</th>
                </tr>
              </thead>
              <tbody>
                {studentsList?.map((item) => (
                  <tr className="table__listingItem" key={item?.id}>
                    <td data-label="Name">
                      {item?.first_name} {item?.last_name}
                    </td>
                    <td
                      onClick={() => handleOpenEditParentKid(item)}
                      data-label="Edit"
                    >
                      <span className="tc dodgerblue button__sp bd">edit</span>
                    </td>
                    <td className="tc" data-label="Delete">
                      <i
                        className="bx bxs-trash-alt red button__sp bd"
                        onClick={() => handleDeleteKid(item?.id)}
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {studentsList?.length === 0 && gradeLevelId !== "" && (
            <h4 className="not__available">
              No students for selected grade level
            </h4>
          )}
          <div className="form__Buttons">
            <button
              className="close__button"
              onClick={() => setOpenMyStudents(false)}
            >
              Close
            </button>
          </div>
        </form>
      </MinDialog>

      {/* child components */}
      {/* to reuse ParentKids components such as EditParent kid, we equate kids to studentsList */}
      {openEditParentKid && (
        <EditParentKid
          openEditParentKid={openEditParentKid}
          setOpenEditParentKid={setOpenEditParentKid}
          currentParentKid={currentParentKid}
          setCurrentParentKid={setCurrentParentKid}
          currentUser="" // this is the parent, it's not needed for editing kids purpose, plus we cannot access the parent since this is purely students list
          kids={studentsList}
          setKids={setStudentsList}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
    gradeLevels: state?.tuition?.gradeLevels,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyStudents);
