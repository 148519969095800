import { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import Header from "./components/Header/Header";
import Sidebar from "./components/Sidebar/Sidebar";
import Home from "./pages/Home/Home";
import NotFound from "./pages/NotFound/NotFound";
import Profile from "./pages/Profile/Profile";
import LearningAreas from "./pages/Teacher/LearningAreas/LearningAreas";
import StudentTestExams from "./pages/Student/StudentTestExams/StudentTestExams";
import TestExamPage from "./pages/Student/TestExamPage/TestExamPage";
import { get_user_data } from "./redux/actions/auth";
import PrivateRoute from "./shared/PrivateRoute";
import TestExamResultsPage from "./pages/Student/TestExamResultsPage/TestExamResultsPage";
import UsersManagement from "./pages/Staff/UsersManagement/UsersManagement";
import AcademicsManagement from "./pages/Staff/AcademicsManagement/AcademicsManagement";
import globals from "./shared/globals";
import MyKids from "./pages/Parent/MyKids/MyKids";
import SubscriptionExpired from "./pages/Student/SubscriptionExpired/SubscriptionExpired";
import Footer from "./components/Footer/Footer";
import Tutoring from "./pages/Teacher/Tutoring/Tutoring";
import LiveClass from "./pages/Common/LiveClass";
import PaymentConfirmation from "./pages/Parent/MyKids/components/PaymentConfirmation";
import AvailableTextExamsPage from "./pages/Student/AvailableTextExamsPage";
import { hideNavBar } from "./shared/scripts";

function App(props) {
  const { getUserData, users_groups } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const access_token = localStorage.getItem("access_token");

  useEffect(() => {
    if (access_token) {
      getUserData(navigate);
    }
  }, [access_token, getUserData, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const RoutesComponent = () => (
    <Routes>
      {/* unprotected routes */}
      <Route exact path="/" element={<Home />} />
      {/* protected routes */}

      <Route exact path="/" element={<PrivateRoute />}>
        {["Teacher"]?.some((allowed_group) => users_groups?.includes(allowed_group)) && (
          <>
            <Route exact path="/learning-areas/" element={<LearningAreas />} />
            <Route exact path="/tutoring/" element={<Tutoring />} />
          </>
        )}
        {["Student"]?.some((allowed_group) => users_groups?.includes(allowed_group)) && (
          <>
            <Route exact path="/test-exams/" element={<StudentTestExams />} />
            <Route exact path="/test-exam-page/:testExamId/" element={<TestExamPage />} />
            <Route exact path="/test-exam-results-page/:userTestExamId/" element={<TestExamResultsPage />} />
            <Route exact path="/subscription-expired/" element={<SubscriptionExpired />} />
            <Route exact path="/available-test-exams-page" element={<AvailableTextExamsPage />} />
          </>
        )}
        {["Staff"]?.some((allowed_group) => users_groups?.includes(allowed_group)) && (
          <>
            <Route exact path="/users-management/" element={<UsersManagement />} />
            <Route exact path="/academics-management/" element={<AcademicsManagement />} />
          </>
        )}
        {["Parent"]?.some((allowed_group) => users_groups?.includes(allowed_group)) && (
          <>
            <Route exact path="/my-kids/" element={<MyKids />} />
          </>
        )}
        {["System Admin"]?.some((allowed_group) => users_groups?.includes(allowed_group)) && <></>}
        {/* common urls */}
        <Route exact path="/payment-confirmation/:paymentId/" element={<PaymentConfirmation />} />
        <Route exact path="/profile/" element={<Profile />} />
        <Route exact path="/live-class/:liveClassId/" element={<LiveClass />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );

  return (
    <div id="body-pd">
      <Header />
      <Sidebar />
      {/* DISPLAY FOR DESKTOP */}
      <div className="main-content desktop-display">
        <RoutesComponent />
      </div>
      {/* DISPLAY FOR MOBILE */}
      <div className="main-content mobile-display" onClick={hideNavBar}>
        <RoutesComponent />
      </div>

      <button
        style={{
          position: "fixed",
          right: "30px",
          background: "green",
          bottom: "10px",
          borderRadius: "50%",
        }}
      >
        <a
          href={`https://wa.me/${globals?.customerSupportNumber}`}
          target="_blank"
          rel="noreferrer"
          style={{
            fontSize: "2rem",
            color: "white",
          }}
        >
          <i class="bx bxl-whatsapp"></i>
        </a>
      </button>
      <Footer />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    users_groups: state.auth.user.users_groups,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserData: (navigate) => dispatch(get_user_data(navigate)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
