import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../components/MinDialog";
import { showError } from "../../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import API from "../../../../shared/axios";

const TestExamReports = (props) => {
  const { openTestExamReports, myKids, userId } = props;
  const { setOpenTestExamReports, startLoading, stopLoading } = props;

  const [currentKidId, setCurrentKidId] = useState("");
  const [results, setResults] = useState([]);

  useEffect(() => {
    if (userId && currentKidId !== "") {
      startLoading();
      const fetchData = async () => {
        await API.get(
          `/api/tuition/parent/parent-view-kid-results/${userId}/${currentKidId}/`
        )
          .then((res) => {
            setResults(res?.data?.results_data);
          })
          .catch((err) => showError(err))
          .finally(() => stopLoading());
      };
      fetchData();
    }
  }, [startLoading, stopLoading, userId, currentKidId]);

  return (
    <MinDialog isOpen={openTestExamReports} maxWidth="700px">
      <div className="dialog">
        <h3>Test Exam Results</h3>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Select kid to view results</label>
          <select
            name=""
            onChange={(e) => setCurrentKidId(e.target.value)}
            value={currentKidId}
          >
            <option value="">--select--</option>
            {myKids?.map((item) => (
              <option value={item?.id} key={item?.id}>
                {item?.first_name}
              </option>
            ))}
          </select>
        </div>
        {results?.length > 0 && (
          <table className="table__listing" rules="all">
            <thead>
              <tr className="table__listingHeader">
                <th>Title</th>
                <th>Learning Area</th>
                <th>Topic</th>
                <th className="tc">Score</th>
              </tr>
            </thead>
            <tbody>
              {results?.map((item) => (
                <tr className="table__listingItem" key={item?.id}>
                  <td data-label="Title">{item?.title}</td>
                  <td data-label="Learning Area">{item?.learning_area}</td>
                  <td data-label="Topic">{item?.topic}</td>
                  <td data-label="Score" className="tc">
                    {item?.marks_scored}%
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}{" "}
        {currentKidId !== "" && results?.length === 0 && (
          <h4 className="not__available">No results available for this kid</h4>
        )}
        <div className="form__Buttons">
          <button
            type="button"
            className="close__button"
            onClick={() => setOpenTestExamReports(false)}
          >
            Close
          </button>
        </div>
      </div>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    myKids: state?.auth?.myKids,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TestExamReports);
