import "react-quill/dist/quill.snow.css";

const globals = {
  liveProduction: true,
  productionHome: "https://mkufunzi.azwgroup.com",
  devHome: "http://localhost:8000",
  customerSupportNumber: "254715047125",
  oneWeekAmount: 100,
  twoWeeksAmount: 200,
  threeWeeksAmount: 300,
  monthlyAmount: 400,
  hourlyTuitionRate: 300,
};

export const REACT_QUILL_MODULES = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    ["link", "image"],
    ["clean"],
  ],
};

export const REACT_QUILL_FORMATS = ["header", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "link", "image"];

export default globals;
