import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import MinDialog from "../../../../components/MinDialog";
import BookSessionDirect from "./BookSessionDirect";
import { connect } from "react-redux";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import API from "../../../../shared/axios";
import { showError } from "../../../../redux/actions/shared";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";

const BookedSessions = (props) => {
  const { openBookedSessions, userId, loading } = props;
  const { setOpenBookedSessions, startLoading, stopLoading } = props;
  const navigate = useNavigate();

  const [openBookSessionDirect, setOpenBookSessionDirect] = useState(false);
  const [sessionsList, setSessionsList] = useState([]);

  useEffect(() => {
    if (userId) {
      const fetchData = async () => {
        startLoading();
        await API.get(
          `/api/tuition/student/maintain-tutoring-sessions/${userId}/`
        )
          .then((res) => {
            setSessionsList(res?.data?.tutoring_sessions_data);
          })
          .catch((err) => {
            if (err?.response?.data?.detail === "invalid_grade_level") {
              window.alert(
                "Error, you must specify your grade level to continue"
              );
              navigate("/profile/");
            } else {
              showError(err);
            }
          })
          .finally(() => stopLoading());
      };
      fetchData();
    }
  }, [userId, startLoading, stopLoading, navigate]);

  return (
    <>
      <MinDialog isOpen={openBookedSessions} maxWidth="700px">
        <div className="dialog" id={loading && "pageLoading"}>
          <h3>Tutoring sessions you have booked</h3>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "45%" }}
            />
          )}
          {sessionsList?.length > 0 ? (
            <table className="table__listing" rules="all" border="1">
              <tr className="table__listingHeader">
                <th>Learning Area</th>
                <th>Time</th>
                <th>Duration</th>
                <th>Status</th>
              </tr>
              {sessionsList?.map((item) => (
                <tr className="table__listingItem" key={item?.id}>
                  <td data-label="Learning Area">{item?.learning_area}</td>
                  <td data-label="Time">
                    {moment(item?.start_time).format("LLL")}
                  </td>
                  <td data-label="Duration">
                    {item?.session_duration} hour(s)
                  </td>
                  <td data-label="Status">
                    {item?.status === "started" && (
                      <Link
                        to={`/live-class/${item?.liveClassId}/`}
                        className="dodgerblue"
                      >
                        Join Now
                      </Link>
                    )}
                    {item?.status === "confirm" && (
                      <a href={`${item?.payment_confirmation_url}`}>Confirm</a>
                    )}
                    {item?.status === "pay_now" && (
                      <a href={`${item?.payment_url}`}>Pay Now</a>
                    )}
                    {item?.status === "not_started" && <span>Upcoming</span>}
                  </td>
                </tr>
              ))}
            </table>
          ) : (
            <h4 className="not__available">No tutoring sessions booked yet</h4>
          )}
          <div className="form__Buttons">
            <button
              type="button"
              className="close__button"
              onClick={() => setOpenBookedSessions(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="continue__button"
              onClick={() => setOpenBookSessionDirect(true)}
            >
              Book Session
            </button>
          </div>
        </div>
      </MinDialog>

      {/* child components */}
      {openBookSessionDirect && (
        <BookSessionDirect
          openBookSessionDirect={openBookSessionDirect}
          setOpenBookSessionDirect={setOpenBookSessionDirect}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookedSessions);
