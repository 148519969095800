import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MaxDialog from "../../../../components/MaxDialog";
import { showError } from "../../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";
import API from "../../../../shared/axios";

const TestExamResults = (props) => {
  const { openTestExamResults, currentTestExam, loading, userId } = props;
  const { setOpenTestExamResults, startLoading, stopLoading } = props;

  const [results, setResults] = useState([]);

  useEffect(() => {
    if (userId && currentTestExam?.id) {
      const fetchData = async () => {
        await API.get(
          `/api/tuition/teacher/get-test-exam-results/${userId}/${currentTestExam?.id}/`
        )
          .then((res) => {
            setResults(res?.data?.results_data);
          })
          .catch((err) => showError(err))
          .finally(() => stopLoading());
      };
      fetchData();
    }
  }, [startLoading, stopLoading, userId, currentTestExam?.id]);

  return (
    <MaxDialog isOpen={openTestExamResults} width="800px">
      <div className="dialog" id={loading && "pageLoading"}>
        <h3>{currentTestExam?.title} results</h3>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        {results?.length > 0 ? (
          <table className="table__listing" rules="all" border="1">
            <thead>
              <tr className="table__listingHeader">
                <th>Student</th>
                <th className="tc">Score</th>
              </tr>
            </thead>
            <tbody>
              {results?.map((item) => (
                <tr className="table__listingItem" key={item?.id}>
                  <td>{item?.student_name}</td>
                  <td className="tc">{item?.marks_scored}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <h4 className="not__available">No results available</h4>
        )}
        <div className="form__Buttons">
          <button
            className="close__button"
            type="button"
            onClick={() => setOpenTestExamResults(false)}
          >
            Close
          </button>
        </div>
      </div>
    </MaxDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TestExamResults);
