const navbarDisplayHelper = (nav) => {
  // const toggle = document.getElementById("header-toggle")

  const bodypd = document.getElementById("body-pd");
  // headerpd = document.getElementById("header");

  const menuBtnShow = document.getElementById("mobile__menuBtnShow");
  const menuBtnHide = document.getElementById("mobile__menuBtnHide");

  menuBtnShow.classList.toggle("hideBtn");
  menuBtnHide.classList.toggle("hideBtn");

  // change icon
  // toggle.classList.toggle("bx-x");

  // add padding to body
  bodypd.classList.toggle("body-pd");

  // add padding to header
  //   headerpd.classList.toggle("body-pd");
};

// toggle sidebar on menu button click

export const showNavbar = () => {
  const nav = document.getElementById("nav-bar");
  // show navbar
  nav.classList.toggle("show");
  navbarDisplayHelper(nav);
};

// hide nav bar when user clicks on any space to hide sidebar
export const hideNavBar = () => {
  const nav = document.getElementById("nav-bar");
  if (nav.classList.contains("show")) {
    nav.classList.remove("show");
    navbarDisplayHelper(nav);
  }
};
