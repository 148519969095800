import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { showError } from "../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../redux/actions/types";
import API from "../../../shared/axios";

import "./TestExamResultsPage.scss";

const TestExamResultsPage = (props) => {
  const { loading, userId } = props;
  const { startLoading, stopLoading } = props;

  const { userTestExamId } = useParams();
  const [userExamResults, setUserExamResults] = useState({});
  const [selectedAnswers, setSelectedAnswers] = useState([]);

  useEffect(() => {
    if (userId && userTestExamId) {
      startLoading();
      const fetchData = async () => {
        await API.get(`/api/tuition/student/student-get-exam-results/${userId}/${userTestExamId}/`)
          .then((res) => {
            setUserExamResults(res?.data?.user_exam_results);
            setSelectedAnswers(res?.data?.user_exam_results?.selected_answers);
          })
          .catch((err) => showError(err))
          .finally(() => stopLoading());
      };
      fetchData();
    }
  }, [userId, startLoading, stopLoading, userTestExamId]);

  return (
    <div className="test__examResultsPage" id={loading && "pageLoading"}>
      <h3>
        {userExamResults?.examTitle} - Your Score: <span className="text-green-600">{userExamResults?.marks_scored}%</span>
      </h3>
      {loading && <CircularProgress style={{ position: "absolute", marginLeft: "45%" }} />}
      <br />
      {userExamResults?.questions?.map((question, index) => (
        <>
          <h4 className="text-lg font-bold">Question: {index + 1}</h4>
          <p>{parse(`${question?.details}`)}</p>
          <div className="choices">
            <h4>Choices</h4>
            <ul className="choices-ul">
              {Object.entries(question.answer_options)?.map(([choiceKey, choice]) => (
                <li key={`${question?.id}-${choiceKey}`} className="show-results">
                  <label
                    className={
                      // color green for the correct answers that the user also selected
                      (selectedAnswers[question?.id].includes(choiceKey) && question.correct_answers?.includes(choiceKey) && "bg-green-400") ||
                      // color red for the wrong answers a user has selected
                      (selectedAnswers[question?.id].includes(choiceKey) && !question.correct_answers?.includes(choiceKey) && "bg-red-400") ||
                      // color for correct answers that the user never selected
                      (!selectedAnswers[question?.id].includes(choiceKey) && question.correct_answers?.includes(choiceKey) && "bg-teal-300")
                    }
                  >
                    <input
                      type={question.answer_type === "multiple_answer" ? "checkbox" : "radio"}
                      name={`${question.id}`}
                      value={choiceKey}
                      checked={selectedAnswers[question?.id].includes(choiceKey)}
                    />
                    <span className="pl-1">{parse(`${choice.contents}`)}</span>
                  </label>
                </li>
              ))}
            </ul>

            <div className="explanation">
              <h3>Explanation:</h3>
              <p>{parse(`${question?.explanation}`)}</p>
            </div>
          </div>
          <br />
        </>
      ))}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TestExamResultsPage);
