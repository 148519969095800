import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import MaxDialog from "../../../../components/MaxDialog";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";
import API from "../../../../shared/axios";
import { showError } from "../../../../redux/actions/shared";

const TestExamsDone = (props) => {
  const { openTestExamsDone, userId, loading } = props;
  const { setOpenTestExamsDone, startLoading, stopLoading } = props;

  const navigate = useNavigate();

  const [attemptedExamsList, setAttemptedExamsList] = useState([]);

  useEffect(() => {
    if (userId) {
      const fetchData = async () => {
        await API.get(`/api/tuition/student/get-student-attempted-test-exams/${userId}/`)
          .then((res) => {
            setAttemptedExamsList(res?.data?.attempted_exams_data);
          })
          .catch((err) => {
            if (err?.response?.data?.detail === "invalid_grade_level") {
              window.alert("Error, you must specify your grade level to continue");
              navigate("/profile/");
            } else {
              showError(err);
            }
          })
          .finally(() => stopLoading());
      };
      fetchData();
    }
  }, [userId, startLoading, stopLoading, navigate]);

  return (
    <MaxDialog isOpen={openTestExamsDone} width="900px">
      <div className="dialog" id={loading && "formLoading"}>
        <h3>Test Exams Done</h3>
        {loading && <CircularProgress style={{ position: "absolute", marginLeft: "45%" }} />}
        {attemptedExamsList?.length > 0 ? (
          <table className="table__listing" rules="all">
            <tr className="table__listingHeader">
              <th>Title</th>
              <th>Learning Area</th>
              <th>Topic</th>
              <th>Score</th>
              <th className="tc">Results</th>
            </tr>
            {attemptedExamsList?.map((item) => (
              <tr className="table__listingItem" key={item?.id}>
                <td data-label="Title">{item?.title}</td>
                <td data-label="Learning Area">{item?.learning_area}</td>
                <td data-label="Topic">{item?.topic}</td>
                <td data-label="Score">{item?.marks_scored}%</td>
                <td className="tc bd dodgerblue" data-label="Results">
                  <Link to={`/test-exam-results-page/${item?.id}/`}>view</Link>
                </td>
              </tr>
            ))}
          </table>
        ) : (
          <h4 className="not__available">No attempted tests yet</h4>
        )}
        <div className="form__Buttons">
          <button className="close__button" type="button" onClick={() => setOpenTestExamsDone(false)}>
            Close
          </button>
        </div>
      </div>
    </MaxDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TestExamsDone);
