// import installed packages
import { useState } from "react";
import { connect } from "react-redux";

// import styles

// import material ui items
import CircularProgress from "@mui/material/CircularProgress";
// import shared/global items
// import components/pages
import MinDialog from "../MinDialog";

// import redux API
import {
  TOGGLE_CHANGE_PASSWORD,
  TOGGLE_LOADING,
} from "../../redux/actions/types";
import API from "../../shared/axios";
import { showError } from "../../redux/actions/shared";

const ChangePassword = (props) => {
  const { loading, changePasswordForm, userId } = props; // extract state from props
  const { startLoading, closeChangePassword, stopLoading } = props; // extract dispatch actions from props

  // internal state
  const [passwords, setPasswords] = useState({
    current_password: "",
    new_password: "",
    confirm_new_password: "",
  });

  //############### destructuring code ###################//
  const { current_password, new_password, confirm_new_password } = passwords;
  //#################end of destructuring ###########//

  // handle change function
  const handleChange = (e) => {
    setPasswords({ ...passwords, [e.target.name]: e.target.value });
  };

  // function to handle password change
  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (
      new_password?.trim() === "" ||
      current_password?.trim() === "" ||
      confirm_new_password?.trim() === ""
    ) {
      return window.alert("Error, all fields are required");
    }
    if (new_password !== confirm_new_password) {
      return window.alert(
        "Error, new password and confirm new password must match"
      );
    }
    // check if password length is at least 6
    if (new_password?.trim()?.length < 6) {
      return window.alert("Error, password must be at least 6 characters");
    }
    startLoading();
    await API.patch(`/api/user/change-password/${userId}/`, passwords)
      .then((res) => {
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };
  return (
    <MinDialog isOpen={changePasswordForm} maxWidth="500px">
      <form className="dialog" id={loading ? "formLoading" : ""}>
        <h3>Change your password here</h3>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Old Password</label>
          <input
            type="password"
            name="current_password"
            value={current_password}
            onChange={handleChange}
            required
          />
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "40%" }}
          />
        )}
        <div className="dialog__rowSingleItem">
          <label htmlFor="">New Password</label>
          <input
            type="password"
            name="new_password"
            value={new_password}
            onChange={handleChange}
            minLength="6"
            required
          />
        </div>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Confirm New Password</label>
          <input
            type="password"
            name="confirm_new_password"
            value={confirm_new_password}
            onChange={handleChange}
            minLength="6"
            required
          />
        </div>
        <div className="form__Buttons">
          <button
            type="button"
            className="close__button"
            onClick={closeChangePassword}
          >
            Close
          </button>
          {/* note here we use onClick instead of onSubmit in the form since this is causing the update profile function 
          to also be called given that this component i.e changepassword is a child component of profile page */}
          <button
            type="submit"
            className="continue__button"
            onClick={handlePasswordChange}
          >
            Submit
          </button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.shared?.loading,
    changePasswordForm: state.auth?.changePasswordForm,
    userId: state.auth.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
    closeChangePassword: () =>
      dispatch({ type: TOGGLE_CHANGE_PASSWORD, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
