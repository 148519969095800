import { Link } from "react-router-dom";

import "./Footer.scss";

const Footer = () => {
  return (
    <div className="footer">
      <div className="columns">
        <div className="footer__one">
          <h3>Mkufunzi</h3>
          <p>
            By{" "}
            <a href="https://azwgroup.com" target="_blank" rel="noopener noreferrer">
              AZW Group Ltd.
            </a>
          </p>
        </div>
        {/* <div className="footer__two">
          <h4>About</h4>
          <ul>
            <li>
              <Link to="#">About Us</Link>
            </li>
            <li>
              <Link to="#">Our Team</Link>
            </li>
          </ul>
        </div> */}
        {/* <div className="footer__three">
          <h4>Terms</h4>
          <ul>
            <li>
              <Link to="/terms-conditions/">Terms &amp; Conditions</Link>
            </li>
            <li>
              <Link to="#">Privacy Policy</Link>
            </li>
          </ul>
        </div> */}
        {/* <div className="footer__four">
          <h4>FAQs</h4>
          <ul>
            <li>
              <Link to="/tutor-faqs/">Tutor FAQs</Link>
            </li>
            <li>
              <Link to="/parent-faqs/">Parent FAQs</Link>
            </li>
            <li>
              <Link to="/student-faqs/">Student FAQs</Link>
            </li>
          </ul>
        </div> */}
      </div>

      <h4 className="credits">Mkufunzi &copy; 2024. All Rights Reserved.</h4>
    </div>
  );
};

export default Footer;
