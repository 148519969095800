import { connect } from "react-redux";

const StaffLinks = (props) => {
  const { Link, pathname, loggedIn } = props;

  return (
    <>
      {loggedIn && (
        <>
          <Link
            to="/users-management/"
            className={
              `${pathname}` === "/users-management/"
                ? "nav__link active"
                : "nav__link"
            }
          >
            <i class="bx bxs-group"></i>
            <span className="nav__name">Users</span>
          </Link>
          <Link
            to="/academics-management/"
            className={
              `${pathname}` === "/academics-management/"
                ? "nav__link active"
                : "nav__link"
            }
          >
            <i class="bx bx-library"></i>
            <span className="nav__name">Academics</span>
          </Link>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedIn: state?.auth?.loggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffLinks);
