import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { TOGGLE_LOGIN } from "../../redux/actions/types";
// styles
import "./NotFound.scss";

function NotFound(props) {
  const { loggedIn } = props;
  const { openLogin } = props;

  const users_groups = useSelector((state) => state?.auth?.user?.users_groups);

  return (
    <div className="not__found">
      <p>Page not found</p>
      <br />
      <div className="action">
        {loggedIn && ["Student"]?.some((allowed_group) => users_groups?.includes(allowed_group)) && (
          <div>
            <button className="add__button mr-10">
              <Link to="/test-exams/" className="add__button">
                Open Tests
              </Link>
            </button>
          </div>
        )}
        {loggedIn && ["Parent"]?.some((allowed_group) => users_groups?.includes(allowed_group)) && (
          <div>
            <button className="add__button mr-10">
              <Link to="/my-kids/" className="add__button">
                My Kids
              </Link>
            </button>
          </div>
        )}
        {loggedIn && ["Teacher"]?.some((allowed_group) => users_groups?.includes(allowed_group)) && (
          <div>
            <button className="add__button mr-10">
              <Link to="/learning-areas/" className="add__button">
                Learning Areas
              </Link>
            </button>
          </div>
        )}
        {!loggedIn && (
          <button className="add__button" onClick={openLogin}>
            Open Tests
          </button>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state?.auth?.loggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openLogin: () => dispatch({ type: TOGGLE_LOGIN, payload: true }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotFound);
