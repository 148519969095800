import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import MinDialog from "../../../../components/MinDialog";
import { connect } from "react-redux";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import API from "../../../../shared/axios";
import { showError } from "../../../../redux/actions/shared";
import CircularProgress from "@mui/material/CircularProgress";

const UpcomingClasses = (props) => {
  const { openUpcomingClasses, userId, loading } = props;
  const { setOpenUpcomingClasses, startLoading, stopLoading } = props;

  const [upcomingClassesList, setUpcomingClassesList] = useState([]);
  const [pageNumber, setPageNumber] = useState("");
  const [hasNextPage, setHasNextPage] = useState(false);

  useEffect(() => {
    if (userId) {
      const fetchData = async () => {
        await API.get(`/api/tuition/teacher/get-upcoming-classes/${userId}`)
          .then((res) => {
            setUpcomingClassesList(res?.data?.upcoming_classes_data);
            if (res?.data?.has_next) {
              setPageNumber(Number(res?.data?.current_page) + 1);
            }
            setHasNextPage(res?.data?.has_next);
          })
          .catch((err) => showError(err))
          .finally(() => stopLoading());
      };
      fetchData();
    }
  }, [userId, startLoading, stopLoading]);

  const loadMorePages = async () => {
    if (hasNextPage) {
      await API.get(
        `/api/tuition/teacher/get-upcoming-classes/${userId}?page=${pageNumber}`
      )
        .then((res) => {
          setUpcomingClassesList([
            ...upcomingClassesList,
            ...res?.data?.upcoming_classes_data,
          ]);
          if (res?.data?.has_next) {
            setPageNumber(Number(res?.data?.current_page) + 1);
          }
          setHasNextPage(res?.data?.has_next);
        })
        .catch((err) => showError(err))
        .finally(() => stopLoading());
    }
  };

  return (
    <MinDialog isOpen={openUpcomingClasses} maxWidth="700px">
      <div className="dialog" id={loading && "pageLoading"}>
        <h3>Upcoming Classes</h3>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        {upcomingClassesList?.length > 0 ? (
          <table className="table__listing" rules="all" border="1">
            <tr className="table__listingHeader">
              <th>Learning Area</th>
              <th>Start Time</th>
              <th>Duration</th>
              <th>Status</th>
            </tr>
            {upcomingClassesList?.map((item) => (
              <tr className="table__listingItem" key={item?.id}>
                <td data-label="Learning Area">{item?.learning_area}</td>
                <td data-label="Start Time">
                  {moment(item?.start_time).format("LLL")}
                </td>
                <td data-label="Duration">{item?.session_duration} hour(s)</td>
                <td data-label="Status">
                  {item?.status === "started" && (
                    <Link
                      to={`/live-class/${item?.liveClassId}/`}
                      className="dodgerblue"
                    >
                      Start Now
                    </Link>
                  )}
                  {item?.status === "not_started" && <span>Upcoming</span>}
                </td>
              </tr>
            ))}
          </table>
        ) : (
          <h4 className="not__available">No upcoming classes</h4>
        )}
        {hasNextPage && (
          <div style={{ textAlign: "center" }}>
            <button
              type="button"
              onClick={loadMorePages}
              style={{
                borderRadius: "10px",
                color: "dodgerblue",
                border: "1px solid dodgerblue",
              }}
            >
              Load More
            </button>
          </div>
        )}
        <div className="form__Buttons">
          <button
            type="button"
            className="close__button"
            onClick={() => setOpenUpcomingClasses(false)}
          >
            Close
          </button>
        </div>
      </div>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpcomingClasses);
