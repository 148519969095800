import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { get_my_kids } from "../../../redux/actions/auth";
import { TOGGLE_LOADING } from "../../../redux/actions/types";
import TestExamReports from "./components/TestExamReports";
import CircularProgress from "@mui/material/CircularProgress";
import MyKidsList from "./components/MyKidsList";
import ActiveSubscriptions from "./components/ActiveSubscriptions";

const MyKids = (props) => {
  const { userId, myKids, loading } = props;
  const { startLoading, getMyKids } = props;

  const [openMyKidsList, setOpenMyKidsList] = useState(false);
  const [openTestExamReports, setOpenTestExamReports] = useState(false);
  const [openActiveSubscriptions, setOpenActiveSubscriptions] = useState(false);

  useEffect(() => {
    if (userId && myKids?.length === 0) {
      startLoading();
      getMyKids(userId);
    }
  }, [startLoading, userId, myKids?.length, getMyKids]);

  return (
    <>
      <div className="table__columns" id={loading && "pageLoading"}>
        <div className="column">
          <h3>My Kids</h3>
          <div className="buttons">
            <button type="button" onClick={() => setOpenMyKidsList(true)}>
              view &amp; update
            </button>
          </div>
        </div>
        {loading && <CircularProgress style={{ position: "absolute", marginLeft: "45%" }} />}
        <div className="column">
          <h3>Test Exam Reports</h3>
          <div className="buttons">
            <button type="button" onClick={() => setOpenTestExamReports(true)}>
              view
            </button>
          </div>
        </div>
        {/* <div className="column">
          <h3>My Kids Subscriptions</h3>
          <div className="buttons">
            <button
              type="button"
              onClick={() => setOpenActiveSubscriptions(true)}
            >
              View
            </button>
          </div>
        </div> */}
      </div>
      {/* child components */}
      {openMyKidsList && <MyKidsList openMyKidsList={openMyKidsList} setOpenMyKidsList={setOpenMyKidsList} />}
      {openTestExamReports && <TestExamReports openTestExamReports={openTestExamReports} setOpenTestExamReports={setOpenTestExamReports} />}
      {/* child components */}
      {openActiveSubscriptions && (
        <ActiveSubscriptions openActiveSubscriptions={openActiveSubscriptions} setOpenActiveSubscriptions={setOpenActiveSubscriptions} />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
    myKids: state?.auth?.myKids,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    getMyKids: (userId) => dispatch(get_my_kids(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyKids);
