import * as actionTypes from "../actions/types";
import { updateObject } from "../utility";

const initialState = {
  gradeLevels: [],
  learningAreas: [],
  myLearningAreas: [],
  gradeLevelLearningAreas: [],
};

const setGradeLevels = (state, payload) => {
  return updateObject(state, {
    gradeLevels: payload?.grade_levels_data,
    learningAreas: payload?.learning_areas_data,
  });
};

const setMyLearningAreas = (state, payload) => {
  return updateObject(state, {
    myLearningAreas: payload,
  });
};

const setGradeLevelLearningAreas = (state, payload) => {
  return updateObject(state, {
    gradeLevelLearningAreas: payload,
  });
};

const tuitionReducer = (state = initialState, action) => {
  switch (action?.type) {
    case actionTypes?.SET_GRADE_LEVELS:
      return setGradeLevels(state, action?.payload);
    case actionTypes.SET_MY_LEARNING_AREAS:
      return setMyLearningAreas(state, action?.payload);
    case actionTypes.SET_GRADE_LEVEL_LEARNING_AREAS:
      return setGradeLevelLearningAreas(state, action?.payload);
    default:
      return state;
  }
};

export default tuitionReducer;
