import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../components/MinDialog";
import { showError } from "../../../../redux/actions/shared";
import { get_grade_levels } from "../../../../redux/actions/tuition";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";
import API from "../../../../shared/axios";
import Topics from "./Topics";

const GradeLevelLearningAreas = (props) => {
  const { openGradeLevelLearningAreas, userId, loading, gradeLevels } = props;
  const {
    setOpenGradeLevelLearningAreas,
    startLoading,
    stopLoading,
    getGradeLevels,
  } = props;

  const [gradeLevelId, setGradeLevelId] = useState("");
  const [gradeLevelLearningAreasList, setGradeLevelLearningAreasList] =
    useState([]);
  // note pageNumber must start with blank to avoid sending the query twice
  const [pageNumber, setPageNumber] = useState("");
  const [hasNextPage, setHasNextPage] = useState(false);
  const [openTopics, setOpenTopics] = useState(false);
  const [currentGradeLevelLearningArea, setCurrentGradeLevelLearningArea] =
    useState({});

  useEffect(() => {
    if (userId && gradeLevels?.length === 0) {
      startLoading();
      getGradeLevels(userId);
    }
  }, [userId, getGradeLevels, startLoading, gradeLevels?.length]);

  useEffect(() => {
    if (userId && gradeLevelId !== "") {
      startLoading();
      const fetchData = async () => {
        await API.get(
          `/api/tuition/staff/maintain-single-grade-level-learning-areas/${userId}/${gradeLevelId}`
        )
          .then((res) => {
            setGradeLevelLearningAreasList(
              res?.data?.grade_level_learning_areas_data
            );
            if (res?.data?.has_next) {
              setPageNumber(Number(res?.data?.current_page) + 1);
            }
            setHasNextPage(res?.data?.has_next);
          })
          .catch((err) => showError(err))
          .finally(() => stopLoading());
      };
      fetchData();
    }
  }, [startLoading, stopLoading, userId, gradeLevelId]);

  const loadMorePages = async () => {
    if (hasNextPage) {
      await API.get(
        `/api/tuition/staff/maintain-single-grade-level-learning-areas/${userId}/${gradeLevelId}?page=${pageNumber}`
      )
        .then((res) => {
          setGradeLevelLearningAreasList([
            ...gradeLevelLearningAreasList,
            ...res?.data?.grade_level_learning_areas_data,
          ]);
          if (res?.data?.has_next) {
            setPageNumber(Number(res?.data?.current_page) + 1);
          }
          setHasNextPage(res?.data?.has_next);
        })
        .catch((err) => showError(err))
        .finally(() => stopLoading());
    }
  };

  const handleOpenLearningAreaTopics = (item) => {
    setCurrentGradeLevelLearningArea(item);
    setOpenTopics(true);
  };

  return (
    <>
      <MinDialog isOpen={openGradeLevelLearningAreas} maxWidth="700px">
        <div className="dialog" id={loading && "pageLoading"}>
          <h3>Select grade level to see learning areas</h3>
          <div className="dialog__rowSingleItem">
            <select
              name=""
              onChange={(e) => setGradeLevelId(e.target.value)}
              value={gradeLevelId}
            >
              <option value="">--select--</option>
              {gradeLevels?.map((item) => (
                <option value={item?.id} key={item?.id}>
                  {item?.name}
                </option>
              ))}
            </select>
          </div>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "45%" }}
            />
          )}
          {gradeLevelId !== "" && gradeLevelLearningAreasList?.length !== 0 && (
            <>
              <table className="table__listing" rules="all" border="1">
                <thead>
                  <tr className="table__listingHeader">
                    <th>Name</th>
                    <th className="tc">Topics</th>
                  </tr>
                </thead>
                <tbody>
                  {gradeLevelLearningAreasList?.map((item) => (
                    <tr className="table__listingItem" key={item?.id}>
                      <td>{item?.name}</td>
                      <td
                        className="tc button__sp dodgerblue bd"
                        onClick={() => handleOpenLearningAreaTopics(item)}
                      >
                        view
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {hasNextPage && (
                <div style={{ textAlign: "center" }}>
                  <button
                    type="button"
                    onClick={loadMorePages}
                    style={{
                      borderRadius: "10px",
                      color: "dodgerblue",
                      border: "1px solid dodgerblue",
                    }}
                  >
                    Load More
                  </button>
                </div>
              )}
            </>
          )}
          {gradeLevelId !== "" && gradeLevelLearningAreasList?.length === 0 && (
            <h4 className="not__available">
              No learning areas for the selected grade level
            </h4>
          )}
          <div className="form__Buttons">
            <button
              className="close__button"
              type="button"
              onClick={() => setOpenGradeLevelLearningAreas(false)}
            >
              Close
            </button>
            <button className="continue__button" type="button">
              Add Learning Area
            </button>
          </div>
        </div>
      </MinDialog>
      {/* child components */}
      {openTopics && (
        <Topics
          openTopics={openTopics}
          setOpenTopics={setOpenTopics}
          currentGradeLevelLearningArea={currentGradeLevelLearningArea}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
    gradeLevels: state?.tuition?.gradeLevels,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
    getGradeLevels: (userId) => dispatch(get_grade_levels(userId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GradeLevelLearningAreas);
