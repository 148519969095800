import React from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../components/MinDialog";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";
import API from "../../../../shared/axios";
import { showError } from "../../../../redux/actions/shared";

const EditTopic = (props) => {
  const {
    openEditTopic,
    currentTopic,
    loading,
    userId,
    gradeLevelLearningAreaId,
    topicsList,
  } = props;
  const {
    setOpenEditTopic,
    setCurrentTopic,
    startLoading,
    stopLoading,
    setTopicsList,
  } = props;

  const handleChange = (e) => {
    setCurrentTopic({ ...currentTopic, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    startLoading();
    await API.patch(
      `/api/tuition/staff/maintain-topics/${userId}/${gradeLevelLearningAreaId}/`,
      { ...currentTopic }
    )
      .then((res) => {
        setTopicsList(
          topicsList?.map((item) =>
            item?.id === currentTopic?.id ? res?.data?.updated_topic_data : item
          )
        );
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <MinDialog isOpen={openEditTopic} maxWidth="600px">
      <form
        className="dialog"
        id={loading && "formLoading"}
        onSubmit={handleSubmit}
      >
        <h3>Enter topic details</h3>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Topic title</label>
          <input
            type="text"
            onChange={handleChange}
            value={currentTopic?.title}
            name="title"
            required
          />
        </div>
        <div className="form__Buttons">
          <button
            type="button"
            onClick={() => setOpenEditTopic(false)}
            className="close__button"
          >
            Close
          </button>
          <button type="submit" className="add__button">
            Submit
          </button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTopic);
