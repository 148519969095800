import React, { useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../MinDialog";
import { showError } from "../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";
import API from "../../shared/axios";

const SetNewPassword = (props) => {
  const { openSetNewPassword, currentUser, loading, userId } = props;
  const { setOpenNewPassword, startLoading, stopLoading } = props;

  const [newPasswords, setNewPasswords] = useState({
    new_password: "",
    confirm_new_password: "",
  });
  const { new_password, confirm_new_password } = newPasswords;

  const handleChange = (e) => {
    setNewPasswords({ ...newPasswords, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (new_password !== confirm_new_password) {
      return window.alert(
        "Error, new password and confirm password must match"
      );
    }
    startLoading();
    await API.post(`/api/user/set-user-new-password/${userId}/`, {
      userToUpdateId: currentUser?.id,
      ...newPasswords,
    })
      .then((res) => {
        setNewPasswords({
          new_password: "",
          confirm_new_password: "",
        });
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <MinDialog isOpen={openSetNewPassword} maxWidth="500px">
      <form
        className="dialog"
        onSubmit={handleSubmit}
        id={loading && "formLoading"}
      >
        <h3>Set new password for {currentUser?.first_name}</h3>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">New Password</label>
          <input
            type="password"
            name="new_password"
            onChange={handleChange}
            value={new_password}
            minLength="6"
            required
          />
        </div>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Confirm New Password</label>
          <input
            type="password"
            name="confirm_new_password"
            onChange={handleChange}
            value={confirm_new_password}
            minLength="6"
            required
          />
        </div>
        <div className="form__Buttons">
          <button
            type="button"
            className="close__button"
            onClick={() => setOpenNewPassword(false)}
          >
            Close
          </button>
          <button type="submit" className="continue__button">
            Submit
          </button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SetNewPassword);
