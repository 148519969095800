import * as actionTypes from "./types";
import * as api from "../../api";
import { showError, stopLoading } from "./shared";

export const signup_user = (body, navigate) => async (dispatch) => {
  await api
    .signupUser(body)
    .then((res) => {
      localStorage.setItem("access_token", res?.data?.access_token);
      dispatch({ type: actionTypes.TOGGLE_SIGNUP, payload: false });
      dispatch(get_user_data(navigate));
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

export const get_user_data = (navigate) => async (dispatch) => {
  await api
    .getUserData()
    .then((res) => {
      dispatch({
        type: actionTypes.AUTH_SUCCESS,
        payload: res?.data?.user_data,
      });
    })
    .catch((err) => {
      // showError(err)
      dispatch(logout(navigate));
    })
    .finally(() => stopLoading(dispatch));
};

export const update_user = (userId, body) => async (dispatch) => {
  await api
    .updateUser(userId, body)
    .then((res) => {
      dispatch({
        type: actionTypes.AUTH_SUCCESS,
        payload: res?.data?.updated_user_data,
      });
      window.alert(res?.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

export const get_my_kids = (userId) => async (dispatch) => {
  await api
    .getMyKids(userId)
    .then((res) => {
      dispatch({
        type: actionTypes.SET_MY_KIDS,
        payload: res?.data?.my_kids_data,
      });
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

export const add_new_kid = (userId, body, resetForm) => async (dispatch) => {
  await api
    .addNewKid(userId, body)
    .then((res) => {
      dispatch({ type: actionTypes.ADD_KID, payload: res?.data?.new_kid_data });
      resetForm();
      window.alert(res?.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

export const update_kid = (userId, body) => async (dispatch) => {
  await api
    .updateKid(userId, body)
    .then((res) => {
      dispatch({
        type: actionTypes.EDIT_KID,
        payload: res.data.updated_kid_data,
      });
      window.alert(res?.data?.detail);
    })
    .catch((err) => showError(err))
    .finally(() => stopLoading(dispatch));
};

export const logout = (navigate) => async (dispatch) => {
  localStorage.clear();
  dispatch({ type: actionTypes.RESET_AUTH_STATE });
  dispatch({ type: actionTypes.RESET_SHARED_STATE });
  navigate("/", { replace: true });
};
