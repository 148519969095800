import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import test_exam from "../../images/test_exam.png";
import tutoring from "../../images/tutoring.png";
import { TOGGLE_LOGIN } from "../../redux/actions/types";

import "./Home.scss";

const Home = (props) => {
  const { openLogin, loggedIn, users_groups } = props;
  return (
    <div className="home">
      {/* <h3 className="heading">
        Primary &amp; secondary school students online tests &amp; tutoring
      </h3> */}
      <h3 className="heading">Kenyan Students online tests &amp; tutoring</h3>

      <div className="banner">
        <h3>How it works</h3>
        <div className="banner__sections">
          <div className="left">
            <h5>1. Take online tests</h5>
            <img src={test_exam} alt="" />
            <p>To discover your weak topics/subjects</p>
          </div>
          <div className="right">
            <h5>2. Get online private tutoring</h5>
            <img src={tutoring} alt="" />
            <p>To strengthen your weak subjects/topics</p>
          </div>
        </div>
      </div>
      <p>
        Over <strong>100</strong> students already benefiting from this program, join them now
      </p>
      <div className="action">
        {loggedIn && ["Student"]?.some((allowed_group) => users_groups?.includes(allowed_group)) && (
          <div>
            <button className="add__button mr-10">
              <Link to="/available-test-exams-page/" className="add__button">
                Open Tests
              </Link>
            </button>
            <button className="add__button">
              <Link to="/test-exams/" className="add__button">
                Get Started Now
              </Link>
            </button>
          </div>
        )}
        {!loggedIn && (
          <button className="add__button" onClick={openLogin}>
            Get Started Now
          </button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedIn: state?.auth?.loggedIn,
    users_groups: state?.auth?.user?.users_groups,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openLogin: () => dispatch({ type: TOGGLE_LOGIN, payload: true }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
