import { connect } from "react-redux";

const TeacherLinks = (props) => {
  const { Link, pathname, loggedIn } = props;

  return (
    <>
      {loggedIn && (
        <>
          <Link to="/learning-areas/" className={`${pathname}` === "/learning-areas/" ? "nav__link active" : "nav__link"}>
            <i class="bx bxs-book-content"></i>
            <span className="nav__name">Learning Areas</span>
          </Link>
          {/* <Link
            to="/tutoring/"
            className={
              `${pathname}` === "/tutoring/" ? "nav__link active" : "nav__link"
            }
          >
            <i class="bx bx-clipboard"></i>
            <span className="nav__name">Tutoring</span>
          </Link> */}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedIn: state?.auth?.loggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TeacherLinks);
