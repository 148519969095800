import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../components/MinDialog";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";
import AddKid from "./AddKid";
import { get_grade_levels } from "../../../../redux/actions/tuition";
import EditKid from "./EditKid";

const MyKidsList = (props) => {
  const { openMyKidsList, userId, loading, gradeLevels, myKids } = props;
  const { setOpenMyKidsList, startLoading, getGradeLevels } = props;

  const [openAddKid, setOpenAddKid] = useState(false);
  const [openEditKid, setOpenEditKid] = useState(false);
  const [currentKid, setCurrentKid] = useState({});

  useEffect(() => {
    if (userId && gradeLevels?.length === 0) {
      startLoading();
      getGradeLevels(userId);
    }
  }, [userId, getGradeLevels, startLoading, gradeLevels?.length]);

  const handleOpenEditKid = (item) => {
    setCurrentKid(item);
    setOpenEditKid(true);
  };

  return (
    <>
      <MinDialog isOpen={openMyKidsList} maxWidth="700px">
        <div className="dialog" id={loading && "pageLoading"}>
          <h3>My Kids</h3>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "45%" }}
            />
          )}
          {myKids?.length > 0 ? (
            <table className="table__listing" rules="all">
              <thead>
                <tr className="table__listingHeader">
                  <th>Name</th>
                  <th>Grade Level</th>
                  <th className="tc">Edit</th>
                </tr>
              </thead>
              <tbody>
                {myKids?.map((item) => (
                  <tr className="table__listingItem" key={item?.id}>
                    <td data-label="Name">
                      {item?.first_name} {item?.last_name}
                    </td>
                    <td data-label="Grade Level">{item?.grade_level_name}</td>
                    <td
                      data-label="Edit"
                      className="tc"
                      onClick={() => handleOpenEditKid(item)}
                    >
                      <span className="tc bd button__sp dodgerblue">edit</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <h4 className="not__available">No kids added yet</h4>
          )}
          <div className="form__Buttons">
            <button
              className="close__button"
              type="button"
              onClick={() => setOpenMyKidsList(false)}
            >
              Close
            </button>
            <button
              className="continue__button"
              type="button"
              onClick={() => setOpenAddKid(true)}
            >
              Add Kid
            </button>
          </div>
        </div>
      </MinDialog>
      {/* child components */}
      {openAddKid && (
        <AddKid openAddKid={openAddKid} setOpenAddKid={setOpenAddKid} />
      )}
      {openEditKid && (
        <EditKid
          openEditKid={openEditKid}
          setOpenEditKid={setOpenEditKid}
          currentKid={currentKid}
          setCurrentKid={setCurrentKid}
        />
      )}
      {/* child components */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
    gradeLevels: state?.tuition?.gradeLevels,
    myKids: state?.auth?.myKids,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    getGradeLevels: (userId) => dispatch(get_grade_levels(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyKidsList);
