import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../components/MinDialog";
import { showError } from "../../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import API from "../../../../shared/axios";
import AddTopic from "./AddTopic";
import EditTopic from "./EditTopic";

const Topics = (props) => {
  const { openTopics, currentGradeLevelLearningArea, userId, loading } = props;
  const { setOpenTopics, startLoading, stopLoading } = props;

  const [topicsList, setTopicsList] = useState([]);
  const [openAddTopic, setOpenAddTopic] = useState(false);
  const [openEditTopic, setOpenEditTopic] = useState(false);
  const [currentTopic, setCurrentTopic] = useState({});

  useEffect(() => {
    if (userId) {
      startLoading();
      const fetchData = async () => {
        await API.get(
          `/api/tuition/staff/maintain-topics/${userId}/${currentGradeLevelLearningArea?.id}/`
        )
          .then((res) => {
            setTopicsList(res?.data?.topics_data);
          })
          .catch((err) => showError(err))
          .finally(() => stopLoading());
      };
      fetchData();
    }
  }, [userId, startLoading, stopLoading, currentGradeLevelLearningArea?.id]);

  const handleOpenEditTopic = (item) => {
    setCurrentTopic(item);
    setOpenEditTopic(true);
  };

  return (
    <>
      <MinDialog isOpen={openTopics}>
        <div className="dialog" id={loading && "pageLoading"}>
          <h3>{currentGradeLevelLearningArea?.name} Topics</h3>
          {topicsList?.length > 0 ? (
            <table className="table__listing" rules="all" border="1">
              <tr className="table__listingHeader">
                <th>Title</th>
                <th className="tc">Edit</th>
              </tr>
              {topicsList?.map((item) => (
                <tr className="table__listingItem" key={item?.id}>
                  <td>{item?.title}</td>
                  <td
                    className="tc dodgerblue button__sp bd"
                    onClick={() => handleOpenEditTopic(item)}
                  >
                    edit
                  </td>
                </tr>
              ))}
            </table>
          ) : (
            <h4 className="not__available">No topics added yet</h4>
          )}
          <div className="form__Buttons">
            <button
              type="button"
              onClick={() => setOpenTopics(false)}
              className="close__button"
            >
              Close
            </button>
            <button
              type="button"
              className="add__button"
              onClick={() => setOpenAddTopic(true)}
            >
              Add Topic
            </button>
          </div>
        </div>
      </MinDialog>
      {openAddTopic && (
        <AddTopic
          openAddTopic={openAddTopic}
          setOpenAddTopic={setOpenAddTopic}
          gradeLevelLearningAreaId={currentGradeLevelLearningArea?.id}
          setTopicsList={setTopicsList}
          topicsList={topicsList}
        />
      )}
      {openEditTopic && (
        <EditTopic
          openEditTopic={openEditTopic}
          setOpenEditTopic={setOpenEditTopic}
          currentTopic={currentTopic}
          setCurrentTopic={setCurrentTopic}
          gradeLevelLearningAreaId={currentGradeLevelLearningArea?.id}
          setTopicsList={setTopicsList}
          topicsList={topicsList}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Topics);
