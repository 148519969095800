import { connect } from "react-redux";

const SystemAdminLinks = (props) => {
  const { Link, pathname, loggedIn } = props;

  return <>{loggedIn && <></>}</>;
};

const mapStateToProps = (state) => {
  return {
    loggedIn: state?.auth?.loggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemAdminLinks);
