import React, { useEffect, useState } from "react";
import MinDialog from "../../../../components/MinDialog";
import { connect } from "react-redux";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import { showError } from "../../../../redux/actions/shared";
import API from "../../../../shared/axios";
import CircularProgress from "@mui/material/CircularProgress";

const MyTopicPerformance = (props) => {
  const { openMyTopicsPerformance, currentPerformance, userId, loading } =
    props;
  const { setOpenMyTopicsPerformance, startLoading, stopLoading } = props;

  const [topicAverageScores, setTopicAverageScores] = useState([]);

  useEffect(() => {
    if (userId) {
      startLoading();
      const fetchData = async () => {
        await API.get(
          `/api/tuition/student/get-user-topics-average-scores/${userId}/${currentPerformance?.grade_level_learning_area_id}/`
        )
          .then((res) => {
            setTopicAverageScores(res?.data?.user_topics_average_scores_data);
          })
          .catch((err) => showError(err))
          .finally(() => stopLoading());
      };
      fetchData();
    }
  }, [
    startLoading,
    stopLoading,
    userId,
    currentPerformance?.grade_level_learning_area_id,
  ]);

  return (
    <>
      <MinDialog isOpen={openMyTopicsPerformance} maxWidth="700px">
        <div className="dialog" id={loading && "pageLoading"}>
          <h3>
            My topics performance for - {currentPerformance?.learning_area_name}
          </h3>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "45%" }}
            />
          )}
          {topicAverageScores?.length > 0 ? (
            <table className="table__listing" rules="all">
              <tr className="table__listingHeader">
                <th>Topic Name</th>
                <th className="tc">Average score</th>
              </tr>
              {topicAverageScores?.map((item) => (
                <tr className="table__listingItem" key={item?.id}>
                  <td data-label="Topic Name">{item?.topic_title}</td>
                  <td data-label="Average Score" className="tc">
                    {item?.average_score}%
                  </td>
                </tr>
              ))}
            </table>
          ) : (
            <h4 className="not__available">
              No topic average scores available
            </h4>
          )}
          <div className="form__Buttons">
            <button
              className="close__button"
              type="button"
              onClick={() => setOpenMyTopicsPerformance(false)}
            >
              Close
            </button>
          </div>
        </div>
      </MinDialog>
      {/* child components */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyTopicPerformance);
