import React, { useState } from "react";
import { connect } from "react-redux";
import RenewSubscription from "../../Parent/MyKids/components/RenewSubscription";

const SubscriptionExpired = (props) => {
  const { userId, kid_name } = props;

  const [openRenewSubscription, setOpenRenewSubscription] = useState(false);

  return (
    <>
      <div className="tc dialog">
        <br />
        <h4>Your subscription has expired</h4>
        <br />
        <button
          type="button"
          className="add__button"
          onClick={() => setOpenRenewSubscription(true)}
        >
          Renew Now
        </button>
      </div>
      {/* child components */}
      {openRenewSubscription && (
        <RenewSubscription
          openRenewSubscription={openRenewSubscription}
          setOpenRenewSubscription={setOpenRenewSubscription}
          currentKid={{ id: userId, kid_name }}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    kid_name: `${state?.auth?.user?.first_name} ${state?.auth?.user?.last_name}`,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionExpired);
