import React, { useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../components/MinDialog";
import { showError } from "../../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";
import API from "../../../../shared/axios";

const AddTeacher = (props) => {
  const { openAddTeacher, loading, userId, teachersList } = props;
  const { setOpenAddTeacher, startLoading, stopLoading, setTeachersList } =
    props;

  const [phoneNumber, setPhoneNumber] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    startLoading();
    await API.post(`/api/user/staff/maintain-teachers/${userId}`, {
      phoneNumber,
    })
      .then((res) => {
        setTeachersList([...teachersList, res?.data?.new_teacher_data]);
        setPhoneNumber("");
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <MinDialog isOpen={openAddTeacher} maxWidth="500px">
      <form
        className="dialog"
        onSubmit={handleSubmit}
        id={loading && "formLoading"}
      >
        <h3>Enter teacher phone number</h3>
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Phone Number</label>
          <input
            type="number"
            onChange={(e) => setPhoneNumber(e.target.value)}
            value={phoneNumber}
            min="1"
            required
          />
        </div>
        <div className="form__Buttons">
          <button
            className="close__button"
            type="button"
            onClick={() => setOpenAddTeacher(false)}
          >
            Close
          </button>
          <button type="submit" className="continue__button">
            Submit
          </button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTeacher);
