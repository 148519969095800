import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MaxDialog from "../../../../components/MaxDialog";
import { showError } from "../../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";
import API from "../../../../shared/axios";
import AddQuestion from "./AddQuestion";
import EditQuestion from "./EditQuestion";

const TestExamQuestions = (props) => {
  const { openTestExamQuestions, currentTestExam, userId, loading } = props;
  const { setOpenTestExamQuestions, startLoading, stopLoading } = props;

  const [questionsList, setQuestionsList] = useState([]);
  const [openAddQuestion, setOpenAddQuestion] = useState(false);
  const [currentQuestionId, setCurrentQuestionId] = useState("");
  const [openEditQuestion, setOpenEditQuestion] = useState(false);

  useEffect(() => {
    if (userId) {
      startLoading();
      const fetchData = async () => {
        await API.get(
          `/api/tuition/teacher/maintain-questions/${userId}/${currentTestExam?.id}/`
        )
          .then((res) => {
            setQuestionsList(res?.data?.questions_data);
          })
          .catch((err) => showError(err))
          .finally(() => stopLoading());
      };
      fetchData();
    }
  }, [userId, startLoading, stopLoading, currentTestExam?.id]);

  const handleOpenEditQuestion = (questionId) => {
    setCurrentQuestionId(questionId);
    setOpenEditQuestion(true);
  };

  const deleteQuestion = async (question_id) => {
    if (
      window.confirm(
        `Once you delete this question, it is not recoverable. Do you want to continue?`
      )
    ) {
      await API.delete(
        `/api/tuition/teacher/delete-question/${userId}/${question_id}/`
      )
        .then((res) => {
          setQuestionsList(
            questionsList?.filter((item) => item?.id !== question_id)
          );
          window.alert(res?.data?.detail);
        })
        .catch((err) => showError(err))
        .finally(() => stopLoading());
    }
  };

  return (
    <>
      <MaxDialog isOpen={openTestExamQuestions} width="800px">
        <div className="dialog" id={loading && "pageLoading"}>
          <h3>{currentTestExam?.title} questions</h3>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", marginLeft: "45%" }}
            />
          )}
          {questionsList?.length > 0 ? (
            <table className="table__listing" rules="all">
              <tr className="table__listingHeader">
                <th>No:</th>
                <th className="tc">Edit</th>
                <td className="tc">Delete</td>
              </tr>
              {questionsList?.map((item, index) => (
                <tr className="table__listingItem" key={item?.id}>
                  <td data-label="No:">Question {index + 1}</td>
                  <td
                    onClick={() => handleOpenEditQuestion(item?.id)}
                    className="tc dodgerblue bd"
                    data-label="Edit"
                  >
                    <span className="button__sp">edit</span>
                  </td>
                  <td className="tc" data-label="Delete">
                    <i
                      className="bx bxs-trash-alt red bd button__sp"
                      onClick={() => deleteQuestion(item?.id)}
                    ></i>
                  </td>
                </tr>
              ))}
            </table>
          ) : (
            <h4 className="not__available">No questions added yet</h4>
          )}
          <div className="form__Buttons">
            <button
              type="button"
              className="close__button"
              onClick={() => setOpenTestExamQuestions(false)}
            >
              Close
            </button>
            <button
              type="button"
              onClick={() => setOpenAddQuestion(true)}
              className="continue__button"
            >
              Add Question
            </button>
          </div>
        </div>
      </MaxDialog>
      {/* child components */}
      {openAddQuestion && (
        <AddQuestion
          openAddQuestion={openAddQuestion}
          setOpenAddQuestion={setOpenAddQuestion}
          setQuestionsList={setQuestionsList}
          questionsList={questionsList}
          testExamId={currentTestExam?.id}
        />
      )}
      {openEditQuestion && (
        <EditQuestion
          openEditQuestion={openEditQuestion}
          setOpenEditQuestion={setOpenEditQuestion}
          testExamId={currentTestExam?.id}
          currentQuestionId={currentQuestionId}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TestExamQuestions);
