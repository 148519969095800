import { connect } from "react-redux";

const StudentLinks = (props) => {
  const { Link, pathname, loggedIn } = props;

  return (
    <>
      {loggedIn && (
        <>
          <Link
            to="/test-exams/"
            className={
              `${pathname}` === "/test-exams/"
                ? "nav__link active"
                : "nav__link"
            }
          >
            <i class="bx bxs-book-content"></i>
            <span className="nav__name">Test Exams</span>
          </Link>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedIn: state?.auth?.loggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentLinks);
