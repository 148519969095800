import { combineReducers } from "@reduxjs/toolkit";

import auth from "./auth";
import shared from "./shared";
import tuition from "./tuition";

export default combineReducers({
  auth,
  shared,
  tuition,
});
