import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MinDialog from "../../../../components/MinDialog";
import { showError } from "../../../../redux/actions/shared";
import { get_all_grade_level_learning_areas } from "../../../../redux/actions/tuition";
import CircularProgress from "@mui/material/CircularProgress";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import API from "../../../../shared/axios";

const AddTeacherLearningAreas = (props) => {
  const {
    openAddTeacherLearningAreas,
    userId,
    loading,
    gradeLevelLearningAreas,
    teacherLearningAreasList,
    currentTeacherId,
  } = props;
  const {
    setOpenAddTeacherLearningAreas,
    startLoading,
    stopLoading,
    getGradeLevelLearningAreas,
    setTeacherLearningAreasList,
  } = props;

  const [selectedLearningAreas, setSelectedLearningAreas] = useState([]);

  useEffect(() => {
    if (userId && gradeLevelLearningAreas?.length === 0) {
      startLoading();
      getGradeLevelLearningAreas(userId);
    }
  }, [
    startLoading,
    userId,
    getGradeLevelLearningAreas,
    gradeLevelLearningAreas?.length,
  ]);

  const handleChange = (e) => {
    const learningAreaItem = gradeLevelLearningAreas?.find(
      (item) => Number(item?.id) === Number(e.target.value)
    );
    // check if item is already selected
    if (
      selectedLearningAreas?.find(
        (item) => Number(item?.id) === Number(e.target.value)
      )
    ) {
      return window.alert("Learning area already selected");
    }
    // check if learning area already exists for this teacher
    if (
      teacherLearningAreasList?.find(
        (item) => Number(item?.id) === Number(e.target.value)
      )
    ) {
      return window.alert(
        "Error, learning area already alloted to this teacher"
      );
    }
    setSelectedLearningAreas([...selectedLearningAreas, learningAreaItem]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedLearningAreas?.length === 0) {
      return window.alert("Error, no learning area selected");
    }
    startLoading();
    await API.post(
      `/api/tuition/staff/maintain-teacher-learning-areas/${userId}/${currentTeacherId}`,
      {
        learningAreasIds: selectedLearningAreas?.map((item) => item?.id),
      }
    )
      .then((res) => {
        setTeacherLearningAreasList([
          ...teacherLearningAreasList,
          ...res?.data?.new_learning_areas_data,
        ]);
        setSelectedLearningAreas([]);
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  const handleRemoveSelectedLearningArea = (itemId) => {
    setSelectedLearningAreas(
      selectedLearningAreas?.filter(
        (item) => Number(item?.id) !== Number(itemId)
      )
    );
  };

  return (
    <MinDialog isOpen={openAddTeacherLearningAreas} maxWidth="600px">
      <form className="dialog" id={loading && "formLoading"}>
        <h3>Add Teacher learning areas</h3>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Learning Area</label>
          {/* note the blank value below to ensure value resets to select after selecting an item */}
          <select name="" onChange={handleChange} value="">
            <option value="">--select--</option>
            {gradeLevelLearningAreas?.map((item) => (
              <option value={item?.id} key={item?.id}>
                {item?.name}
              </option>
            ))}
          </select>
        </div>
        <br />
        {loading && (
          <CircularProgress
            style={{ position: "absolute", marginLeft: "45%" }}
          />
        )}
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Selected Learning Areas</label>
          {selectedLearningAreas?.length > 0 ? (
            <table className="table__listing" rules="all" border="1">
              <thead>
                <tr className="table__listingHeader">
                  <th>Name</th>
                  <th className="tc">Remove</th>
                </tr>
              </thead>
              <tbody>
                {selectedLearningAreas?.map((item) => (
                  <tr className="table__listingItem" key={item?.id}>
                    <td>{item?.name}</td>
                    <td className="tc">
                      <i
                        className="bx bxs-trash-alt red button__sp bd"
                        onClick={() =>
                          handleRemoveSelectedLearningArea(item?.id)
                        }
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <h4 className="red">No selected learning areas yet</h4>
          )}
        </div>
        <div className="form__Buttons">
          <button
            className="close__button"
            type="button"
            onClick={() => setOpenAddTeacherLearningAreas(false)}
          >
            Close
          </button>
          <button
            className="continue__button"
            type="button"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </form>
    </MinDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state?.shared?.loading,
    userId: state?.auth?.user?.id,
    gradeLevelLearningAreas: state?.tuition?.gradeLevelLearningAreas,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGradeLevelLearningAreas: (userId) =>
      dispatch(get_all_grade_level_learning_areas(userId)),
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddTeacherLearningAreas);
