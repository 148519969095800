import * as actionTypes from "../actions/types";

import { updateObject } from "../utility";

const initialState = {
  user: {},
  myKids: [],
  loggedIn: false,
};

const toggleLogin = (state, payload) => {
  return updateObject(state, {
    loginForm: payload,
  });
};

const toggleSignup = (state, payload) => {
  return updateObject(state, {
    signupForm: payload,
  });
};

const authSuccess = (state, payload) => {
  return updateObject(state, {
    user: payload,
    loggedIn: true,
  });
};

const toggleChangePassword = (state, payload) => {
  return updateObject(state, {
    changePasswordForm: payload,
  });
};

const setMyKids = (state, payload) => {
  return updateObject(state, {
    myKids: payload,
  });
};

const addNewKid = (state, payload) => {
  return updateObject(state, {
    myKids: [...state?.myKids, payload],
  });
};

const updateKid = (state, payload) => {
  return updateObject(state, {
    myKids: state?.myKids?.map((item) =>
      item?.id === payload?.id ? payload : item
    ),
  });
};

const authReducer = (state = initialState, action) => {
  switch (action?.type) {
    case actionTypes.TOGGLE_LOGIN:
      return toggleLogin(state, action?.payload);
    case actionTypes.TOGGLE_SIGNUP:
      return toggleSignup(state, action?.payload);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action?.payload);
    case actionTypes.TOGGLE_CHANGE_PASSWORD:
      return toggleChangePassword(state, action?.payload);
    case actionTypes.SET_MY_KIDS:
      return setMyKids(state, action?.payload);
    case actionTypes.ADD_KID:
      return addNewKid(state, action?.payload);
    case actionTypes.EDIT_KID:
      return updateKid(state, action?.payload);
    case actionTypes.RESET_AUTH_STATE:
      return initialState;
    default:
      return state;
  }
};

export default authReducer;
