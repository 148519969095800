import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  get_grade_levels,
  get_my_learning_areas,
} from "../../../redux/actions/tuition";
import { TOGGLE_LOADING } from "../../../redux/actions/types";

import TestExams from "./components/TestExams";
import MyParents from "./components/MyParents";
import MyStudents from "./components/MyStudents";

const LearningAreas = (props) => {
  const { myLearningAreas, userId, loading, gradeLevels } = props;
  const { getMyLearningAreas, startLoading, getGradeLevels } = props;

  useEffect(() => {
    if (userId && myLearningAreas?.length === 0) {
      startLoading();
      getMyLearningAreas(userId);
    }
  }, [userId, startLoading, getMyLearningAreas, myLearningAreas?.length]);

  useEffect(() => {
    if (userId && gradeLevels?.length === 0) {
      startLoading();
      getGradeLevels(userId);
    }
  }, [userId, getGradeLevels, startLoading, gradeLevels?.length]);

  const [openTestExams, setOpenTestExams] = useState(false);
  const [openMyStudents, setOpenMyStudents] = useState(false);
  const [openMyParents, setOpenMyParents] = useState(false);

  return (
    <>
      <div className="table__parent" id={loading && "pageLoading"}>
        <div className="table__columns">
          <div className="column">
            <h3>Test Exams</h3>
            <div className="buttons">
              <button type="button" onClick={() => setOpenTestExams(true)}>
                View &amp; Update
              </button>
            </div>
          </div>
          <div className="column">
            <h3>My Students</h3>
            <div className="buttons">
              <button type="button" onClick={() => setOpenMyStudents(true)}>
                View
              </button>
            </div>
          </div>
          <div className="column">
            <h3>My Parents</h3>
            <div className="buttons">
              <button type="button" onClick={() => setOpenMyParents(true)}>
                View
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* child components */}
      {openTestExams && (
        <TestExams
          openTestExams={openTestExams}
          setOpenTestExams={setOpenTestExams}
        />
      )}
      {openMyStudents && (
        <MyStudents
          openMyStudents={openMyStudents}
          setOpenMyStudents={setOpenMyStudents}
        />
      )}
      {openMyParents && (
        <MyParents
          openMyParents={openMyParents}
          setOpenMyParents={setOpenMyParents}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    myLearningAreas: state?.tuition?.myLearningAreas,
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
    gradeLevels: state?.tuition?.gradeLevels,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMyLearningAreas: (userId) => dispatch(get_my_learning_areas(userId)),
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    getGradeLevels: (userId) => dispatch(get_grade_levels(userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LearningAreas);
