import React, { useEffect, useState } from "react";
import ContentEditable from "react-contenteditable";
import { connect } from "react-redux";
import MaxDialog from "../../../../components/MaxDialog";
import { showError } from "../../../../redux/actions/shared";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import CircularProgress from "@mui/material/CircularProgress";
import API from "../../../../shared/axios";
import ReactQuill from "react-quill";
import { REACT_QUILL_FORMATS, REACT_QUILL_MODULES } from "../../../../shared/globals";

const EditQuestion = (props) => {
  const { openEditQuestion, loading, userId, testExamId, currentQuestionId } = props;
  const { startLoading, stopLoading, setOpenEditQuestion } = props;

  const [details, setDetails] = useState("");
  const [is_active, setIsActive] = useState(false);
  const [explanation, setExplanation] = useState("");
  const [optionA, setOptionA] = useState("");
  const [optionB, setOptionB] = useState("");
  const [optionC, setOptionC] = useState("");
  const [optionD, setOptionD] = useState("");
  const [answer_type, setAnswerType] = useState("");
  const [correctOptions, setCorrectOptions] = useState({ optionA: false, optionB: false, optionC: false, optionD: false });

  const handleCorrectOptionsChange = (e) => {
    setCorrectOptions({ ...correctOptions, [e.target.name]: e.target.checked });
  };

  useEffect(() => {
    if (userId && currentQuestionId) {
      startLoading();
      const fetchData = async () => {
        await API.get(`/api/tuition/teacher/get-question-details/${userId}/${currentQuestionId}/`)
          .then((res) => {
            setDetails(res?.data?.question_details?.details);
            setExplanation(res?.data?.question_details?.explanation);
            setAnswerType(res?.data?.question_details?.answer_type);
            setIsActive(res.data?.question_details?.is_active);
            setOptionA(res?.data?.question_details?.answer_options["optionA"]["contents"]);
            setOptionB(res?.data?.question_details?.answer_options["optionB"]["contents"]);
            setOptionC(res?.data?.question_details?.answer_options["optionC"]["contents"]);
            setOptionD(res?.data?.question_details?.answer_options["optionD"]["contents"]);
            for (const [optionKey, optionData] of Object.entries(res?.data?.question_details?.answer_options)) {
              setCorrectOptions((prev) => ({ ...prev, [optionKey]: optionData["is_correct"] }));
            }
          })
          .catch((err) => showError(err))
          .finally(() => stopLoading());
      };
      fetchData();
    }
  }, [userId, currentQuestionId, startLoading, stopLoading]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (details?.trim() === "") {
      return window.alert("Error, please indicate the question details and reasons for correct answer");
    }
    if (optionA?.trim() === "" || optionB?.trim() === "" || optionC?.trim() === "" || optionD?.trim() === "") {
      return window.alert("All options must be specified");
    }
    const correctAnswersLength = Object.values(correctOptions)?.filter((item) => item === true)?.length;
    if (answer_type === "single_answer" && correctAnswersLength !== 1) {
      return window.alert("This question should have only one correct answer");
    } else if (answer_type === "multiple_answer" && correctAnswersLength < 1) {
      return window.alert("This question should have at least one correct answer");
    }
    startLoading();
    const answerOptions = {};
    answerOptions["optionA"] = { contents: optionA, is_correct: correctOptions?.optionA };
    answerOptions["optionB"] = { contents: optionB, is_correct: correctOptions?.optionB };
    answerOptions["optionC"] = { contents: optionC, is_correct: correctOptions?.optionC };
    answerOptions["optionD"] = { contents: optionD, is_correct: correctOptions?.optionD };
    await API.patch(`/api/tuition/teacher/maintain-questions/${userId}/${testExamId}/`, {
      details,
      currentQuestionId,
      created_by: userId,
      test_exam: testExamId,
      explanation,
      answer_type,
      answer_options: answerOptions,
      is_active,
    })
      .then((res) => {
        window.alert(res?.data?.detail);
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <MaxDialog isOpen={openEditQuestion} width="900px">
      <form className="dialog" id={loading && "formLoading"} onSubmit={handleSubmit}>
        <h3>Enter question details</h3>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Details</label>
          <ReactQuill modules={REACT_QUILL_MODULES} formats={REACT_QUILL_FORMATS} value={details} onChange={setDetails} />
        </div>
        <div class="dialog-row-single-item">
          <label htmlFor="">Answer type</label>
          <select name="answerType" onChange={(e) => setAnswerType(e.target.value)} value={answer_type} required>
            <option value="" selected disabled>
              Select type
            </option>
            <option value="single_answer">Single answer</option>
            <option value="multiple_answer">Multiple answer</option>
          </select>
        </div>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Option A</label>
          <input type="text" name="optionA" onChange={(e) => setOptionA(e.target.value)} value={optionA} required />
          <label className="option-checkbox">
            <input type="checkbox" name="optionA" checked={correctOptions?.optionA} onChange={handleCorrectOptionsChange} />
            <span>Tick this box if option A is a correct answer</span>
          </label>
        </div>
        {loading && <CircularProgress style={{ position: "absolute", marginLeft: "45%" }} />}
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Option B</label>
          <input type="text" name="optionB" onChange={(e) => setOptionB(e.target.value)} value={optionB} required />
          <label className="option-checkbox">
            <input type="checkbox" name="optionB" checked={correctOptions?.optionB} onChange={handleCorrectOptionsChange} />
            <span>Tick this box if option B is a correct answer</span>
          </label>
        </div>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Option C</label>
          <input type="text" name="optionC" onChange={(e) => setOptionC(e.target.value)} value={optionC} required />
          <label className="option-checkbox">
            <input type="checkbox" name="optionC" checked={correctOptions?.optionC} onChange={handleCorrectOptionsChange} />
            <span>Tick this box if option C is a correct answer</span>
          </label>
        </div>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Option D</label>
          <input type="text" name="optionD" onChange={(e) => setOptionD(e.target.value)} value={optionD} required />
          <label className="option-checkbox">
            <input type="checkbox" name="optionD" checked={correctOptions?.optionD} onChange={handleCorrectOptionsChange} />
            <span>Tick this box if option D is a correct answer</span>
          </label>
        </div>
        <div className="dialog__rowSingleItem">
          <label htmlFor="">Explanation</label>
          <ReactQuill modules={REACT_QUILL_MODULES} formats={REACT_QUILL_FORMATS} value={explanation} onChange={setExplanation} />
        </div>
        <label className="option-checkbox">
          <input type="checkbox" name="optionA" checked={is_active} onChange={(e) => setIsActive(e.target.checked)} />
          <span>Tick this box if question is finished including options/explanation</span>
        </label>
        <div className="form__Buttons">
          <button type="button" className="close__button" onClick={() => setOpenEditQuestion(false)}>
            Close
          </button>
          <button type="submit" className="continue__button">
            Update
          </button>
        </div>
      </form>
    </MaxDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditQuestion);
