import React from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import "./PaymentConfirmation.scss";
import API from "../../../../shared/axios";
import { TOGGLE_LOADING } from "../../../../redux/actions/types";
import { showError } from "../../../../redux/actions/shared";
import CircularProgress from "@mui/material/CircularProgress";

const PaymentConfirmation = (props) => {
  const { paymentId } = useParams();
  const navigate = useNavigate();

  const { userId, loading } = props;
  const { startLoading, stopLoading } = props;

  const confirmPayment = async () => {
    startLoading();
    await API.get(
      `/api/subscription/confirm-mpesa-express-payment/${userId}/${paymentId}/`
    )
      .then((res) => {
        window.alert(res?.data?.detail);
        // since this may be the parent or kid renewing, we redirect to the homepage
        navigate("/", { replace: true });
      })
      .catch((err) => showError(err))
      .finally(() => stopLoading());
  };

  return (
    <div className="payment__confirmation" id={loading && "pageLoading"}>
      <h3>Confirm Your Payment</h3>
      {loading && (
        <CircularProgress style={{ position: "absolute", marginLeft: "0%" }} />
      )}
      <p>After paying, click the button below to complete your payment</p>
      <button type="button" className="add__button" onClick={confirmPayment}>
        Confirm Payment
      </button>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state?.auth?.user?.id,
    loading: state?.shared?.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch({ type: TOGGLE_LOADING, payload: true }),
    stopLoading: () => dispatch({ type: TOGGLE_LOADING, payload: false }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentConfirmation);
